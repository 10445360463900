import { useContext } from 'react';
import { IconButton, Typography, CardProps } from '@mui/material';
import UserContext from 'src/contexts/UserContext';
import { Color } from 'src/Color';
import { Switch } from '../../general/Switch';
import Card from '../../general/Card';
import useShowLoading from 'src/hooks/feedback/useLoading';
import FeedbackContext from '../../../contexts/FeedbackContext';

const AccountPrivacyInfo = ({ sx, ...props }: CardProps) => {
    return (
        <Card
            {...props}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                textAlign: 'center',
                pt: 3,
                ...sx,
            }}
        >
            <i
                className="fa-solid fa-lock"
                style={{
                    padding: '0.5rem',
                    color: Color.Purple,
                    borderRadius: '50%',
                    fontSize: '1.2rem',
                    border: `4px dotted ${Color.Purple}`,
                }}
            />
            <Typography my={1} fontFamily="Nunito Sans" fontSize="1.4rem" fontWeight={600} lineHeight="24px">
                Account Privacy
            </Typography>
            <Typography fontFamily="Nunito Sans" fontSize="0.95rem" mb={1.5}>
                When your account is set to public, your profile and IRCODES can be seen by anyone, even if they don't
                have an IRCODE account.
            </Typography>
            <Typography fontFamily="Nunito Sans" fontSize="0.95rem">
                When your account is set to private, only you can view your profile and IRCODES. If someone tries to
                scan one of your IRCODES, they will see a message informing them that the IRCODE is privately owned and
                cannot be accessed.
            </Typography>
        </Card>
    );
};

const AccountPrivacyForm = ({ onInfo, sx, ...props }: CardProps & { onInfo?: () => void }) => {
    const { notify } = useContext(FeedbackContext)!;
    const [showLoading, setShowLoading] = useShowLoading();
    const { user, togglePrivateAccount } = useContext(UserContext)!;

    const handleTogglePrivateAccount = async () => {
        setShowLoading(true);
        try {
            await togglePrivateAccount();
        } catch (error) {
            notify('Error updating account privacy', (error as Error).message);
            console.error('Error updating account privacy', error);
        } finally {
            setShowLoading(false);
        }
    };
    return (
        <Card {...props} sx={{ display: 'flex', alignItems: 'center', ...sx }}>
            {onInfo && (
                <IconButton onClick={onInfo} sx={{ mr: 1, p: 0 }} size="small">
                    <i className="fa-light fa-circle-question" />
                </IconButton>
            )}
            <Typography fontFamily="Nunito Sans" flexGrow={1}>
                Private Account
            </Typography>
            <Switch onChange={handleTogglePrivateAccount} disabled={showLoading} checked={!!user?.privateAccount} />
        </Card>
    );
};

export { AccountPrivacyInfo, AccountPrivacyForm };
