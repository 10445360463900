import { useContext, useState } from 'react';
import { Stack, Box, Typography, Dialog, Button, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Color } from '../../../../Color';
import MediaContext, {
    ImageOperation,
    TMedia,
    imageOperationFromImage,
    Upload,
} from '../../../../contexts/MediaContext';
import { Image } from '../../../../types/Image';
import { Asset } from 'src/util/image';
import MetaContext, {
    MetaData as IMetaData,
    TMeta,
    addOrMergeCustom,
    metaFieldForMetaType,
    newMetaField,
    validateMeta,
} from '../../../../contexts/MetaContext';
import GrayBoxButton from '../../../general/GrayBoxButton';
import FeedbackContext, { TFeedback } from '../../../../contexts/FeedbackContext';
import Cropper from '../../../image/CropperExtended';
import { useNavigate } from 'react-router-dom';
import AddCustom from '../MetaData/AddCustom';
import { CardType, MetaContent, MetaType, TCardType } from '../../../../types/MetaTypes';
import { CardTypeForm } from '../MetaData/CardType';
import { ArtistNameForm } from '../MetaData/ArtistName';
import { DescriptionForm } from '../MetaData/Description';
import { TitleForm } from '../MetaData/Title';
import { LinkForm } from '../MetaData/Link';
import { YearForm } from '../MetaData/Year';
import { MediumForm } from '../MetaData/Medium';
import { SizeForm } from '../MetaData/Size';
import { TagsForm } from '../MetaData/Tags';
import { CustomForm } from '../MetaData/Custom';
import { EmailForm } from '../MetaData/Email';
import { PhoneForm } from '../MetaData/Phone';
import { PriceForm } from '../MetaData/Price';
import { GalleryNameForm } from '../MetaData/GalleryName';
import { ProvenanceForm } from '../MetaData/Provenance';
import { DisplayLocationForm } from '../MetaData/DisplayLocation';
import useIsImageOwner from 'src/hooks/useIsImageOwner';
import { ProductLinkForm } from '../MetaData/ProductLink';
import useProducts from 'src/hooks/useProducts';
import { FirstNameForm } from '../MetaData/FirstName';
import { LastNameForm } from '../MetaData/LastName';
import { CompanyForm } from '../MetaData/Company';
import { MetaErrors } from '../../../../types/MetaValidation';
import useScopeMetaErrors from '../../../../hooks/metaErrors/useScopeMetaErrors';
import { JobTitleForm } from '../MetaData/JobTitle';

interface IrcodeInformationTopProps {
    image: Image;
    meta: IMetaData;
    setMeta: (meta: IMetaData) => void;
    onCropped: (imageOperation: ImageOperation<Upload>) => void;
}

export function IrcodeInformationTop({ image, meta, setMeta, onCropped }: IrcodeInformationTopProps) {
    const { confirm, setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { products } = useProducts(image.campaignID);

    const [showAddCustom, setShowAddCustom] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    // This is for local operations
    const [imageOperation, setImageOperation] = useState<ImageOperation<any>>();
    const [croppedImage, setCroppedImage] = useState<Asset>();
    const [showMore, setShowMore] = useState(false);

    // const showCropper = imageOperation !== undefined
    //     && imageOperation.operation.type === Type.Crop;

    const onChange = (metaType: MetaType, metaContent: MetaContent) => {
        const newMeta = structuredClone(meta) as IMetaData;
        const index = newMeta.Meta.findIndex(m => m.metaType === metaType);
        if (index !== -1) {
            newMeta.Meta[index].metaContent = metaContent;
        } else {
            newMeta.Meta.push(newMetaField(metaType, metaContent));
        }
        setMeta(newMeta);
    };

    const cardType =
        (metaFieldForMetaType(meta.Meta, MetaType.CardType)?.metaContent as TCardType | undefined)?.cardType ??
        CardType.ArtCard;

    const onCropperProgress = (progress: ImageOperation<any>) => {
        setImageOperation(progress);
    };

    return (
        <>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={4}
                sx={{
                    p: 3,
                }}
            >
                <Stack
                    key="meta"
                    direction="column"
                    spacing={3}
                    sx={{
                        width: { xs: '100%', md: '50%' },
                        minWidth: { md: 400, sm: 300, xs: 'unset' },
                    }}
                >
                    <CardTypeForm
                        metaField={metaFieldForMetaType(meta.Meta, MetaType.CardType)}
                        onChange={async (metaContent: MetaContent) => {
                            if (
                                await confirm({
                                    title: 'Are your sure?',
                                    message: 'Some data may be lost',
                                    yes: 'Switch Templates',
                                    no: 'Cancel',
                                })
                            ) {
                                onChange(MetaType.CardType, metaContent);
                            }
                        }}
                    />
                    {cardType === CardType.ArtCard && (
                        <>
                            <ArtistNameForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.ArtistName)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.ArtistName, metaContent);
                                }}
                            />
                            <TitleForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Title)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Title, metaContent);
                                }}
                            />
                            <YearForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Year)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Year, metaContent);
                                }}
                            />
                            <MediumForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Medium)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Medium, metaContent);
                                }}
                            />
                            <SizeForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Size)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Size, metaContent);
                                }}
                            />
                            <LinkForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Link)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Link, metaContent);
                                }}
                                // showAddLink={true}
                            />
                            <ProductLinkForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.ProductLink)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.ProductLink, metaContent);
                                }}
                                products={products}
                            />
                            <Accordion
                                onChange={(event, expanded) => {
                                    setShowMore(expanded);
                                }}
                            >
                                <AccordionSummary expandIcon={<i className="fa-solid fa-chevron-down"></i>}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito Sans',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            lineHeight: '20px',
                                            letterSpacing: '0.01em',
                                        }}
                                    >
                                        {showMore ? 'Show Less' : 'Show More'}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" spacing={3}>
                                        <DescriptionForm
                                            metaField={metaFieldForMetaType(meta.Meta, MetaType.Description)}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Description, metaContent);
                                            }}
                                        />
                                        <TagsForm
                                            metaField={metaFieldForMetaType(meta.Meta, MetaType.Tags)}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Tags, metaContent);
                                            }}
                                        />
                                        <PriceForm
                                            metaField={metaFieldForMetaType(meta.Meta, MetaType.Price)}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Price, metaContent);
                                            }}
                                        />
                                        <GalleryNameForm
                                            metaField={metaFieldForMetaType(meta.Meta, MetaType.GalleryName)}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.GalleryName, metaContent);
                                            }}
                                        />
                                        <DisplayLocationForm
                                            metaField={metaFieldForMetaType(meta.Meta, MetaType.DisplayLocation)}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.DisplayLocation, metaContent);
                                            }}
                                        />
                                        <ProvenanceForm
                                            metaField={metaFieldForMetaType(meta.Meta, MetaType.Provenance)}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Provenance, metaContent);
                                            }}
                                        />
                                        <EmailForm
                                            metaField={metaFieldForMetaType(meta.Meta, MetaType.Email)}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Email, metaContent);
                                            }}
                                        />
                                        <PhoneForm
                                            metaField={metaFieldForMetaType(meta.Meta, MetaType.Phone)}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Phone, metaContent);
                                            }}
                                        />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <CustomForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Custom)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Custom, metaContent);
                                }}
                            />
                        </>
                    )}
                    {cardType === CardType.General && (
                        <>
                            <TitleForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Title)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Title, metaContent);
                                }}
                            />
                            <LinkForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Link)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Link, metaContent);
                                }}
                            />
                            <ProductLinkForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.ProductLink)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.ProductLink, metaContent);
                                }}
                                products={products}
                            />
                            <DescriptionForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Description)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Description, metaContent);
                                }}
                            />
                            <TagsForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Tags)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Tags, metaContent);
                                }}
                            />
                            <CustomForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Custom)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Custom, metaContent);
                                }}
                            />
                        </>
                    )}
                    {cardType === CardType.Contact && (
                        <>
                            <FirstNameForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.FirstName)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.FirstName, metaContent);
                                }}
                            />
                            <LastNameForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.LastName)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.LastName, metaContent);
                                }}
                            />
                            <CompanyForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Company)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Company, metaContent);
                                }}
                            />
                            <JobTitleForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.JobTitle)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.JobTitle, metaContent);
                                }}
                            />
                            <EmailForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Email)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Email, metaContent);
                                }}
                            />
                            <PhoneForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Phone)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Phone, metaContent);
                                }}
                            />
                            <LinkForm
                                metaField={metaFieldForMetaType(meta.Meta, MetaType.Link)}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Link, metaContent);
                                }}
                            />
                        </>
                    )}

                    {cardType !== CardType.Contact && (
                        <>
                            <GrayBoxButton
                                onClick={() => {
                                    setShowAddCustom(true);
                                }}
                                sx={{
                                    textAlign: 'center',
                                }}
                            >
                                <i
                                    className="fa-solid fa-plus"
                                    style={
                                        {
                                            // color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                        }
                                    }
                                ></i>
                                <Typography
                                    sx={{
                                        display: 'inline-block',
                                        ml: 0.5,

                                        // TODO: Make variant...
                                        fontFamily: 'Nunito Sans',
                                        fontSize: 20,
                                        fontWeight: 400,
                                        lineHeight: '28px',
                                        letterSpacing: '0.01em',

                                        // color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                        // color: Color.PrimaryDarkGrayBlue,
                                    }}
                                >
                                    Add custom field
                                </Typography>
                            </GrayBoxButton>
                            <Dialog
                                fullScreen
                                open={showAddCustom}
                                onClose={() => setShowAddCustom(false)}
                                sx={{
                                    '& .MuiDialog-paper': {
                                        // TODO: Color.ts
                                        backgroundColor: '#00000AAA',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        // TODO: Color.ts
                                        border: '1px solid #A5A5D1',
                                        borderRadius: 3,
                                        overflow: 'hidden',
                                    }}
                                >
                                    <AddCustom
                                        onSelection={(field: string) => {
                                            const customMetaField = metaFieldForMetaType(meta.Meta, MetaType.Custom);
                                            const newMetaField = addOrMergeCustom(customMetaField, field);
                                            const newMeta = {
                                                ...meta,
                                                Meta: [
                                                    ...meta.Meta.filter(m => m.metaType !== MetaType.Custom),
                                                    newMetaField,
                                                ],
                                            };
                                            setMeta(newMeta);
                                            setShowAddCustom(false);
                                        }}
                                        onClose={() => {
                                            setShowAddCustom(false);
                                        }}
                                    />
                                </Box>
                            </Dialog>
                        </>
                    )}
                </Stack>

                {
                    <Stack
                        key="cropper"
                        direction="column"
                        spacing={4}
                        sx={{
                            width: { xs: '100%', md: '50%' },
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                height: 368,
                                borderRadius: 1,
                                objectFit: 'contain',
                            }}
                            src={croppedImage?.url ?? image?.imageUrl}
                            alt=""
                        />
                        <Stack direction={{ xs: 'column', md: 'row' }}>
                            <Button
                                variant="irdbGray"
                                sx={{
                                    height: 58,
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    lineHeight: '26px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                    textTransform: 'none',
                                }}
                                onClick={async () => {
                                    setImageOperation(await imageOperationFromImage(image));
                                    setShowCropper(true);
                                }}
                            >
                                <i className="fa-solid fa-crop-simple"></i> Crop image
                            </Button>
                            {false && (
                                <Button
                                    variant="irdbGray"
                                    sx={{
                                        height: 58,
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        lineHeight: '26px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                    }}
                                >
                                    <i className="fa-light fa-images fa-2xl" style={{ color: Color.Purple }}></i>Upload
                                    new
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                }
            </Stack>
            <Dialog open={showCropper}>
                <Cropper
                    imageOperation={imageOperation}
                    upload
                    onProgress={onCropperProgress}
                    onSuccess={i => {
                        setCroppedImage(i.cropped);
                        setShowCropper(false);
                        onCropped(i);
                    }}
                    onRetake={() => {
                        setShowCropper(false);
                        setImageOperation(undefined);
                    }}
                    onCancel={() => {
                        setShowCropper(false);
                        setImageOperation(undefined);
                    }}
                />
            </Dialog>
        </>
    );
}

interface IrcodeInformationBottomProps {
    image: Image;
    meta: IMetaData;
    onUpdate: () => void;
    onMetaSaveErrors: (metaErrors: MetaErrors) => void;
}

export function IrcodeInformationBottom({ image, meta, onUpdate, onMetaSaveErrors }: IrcodeInformationBottomProps) {
    const navigate = useNavigate();
    const { confirm, notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { remove } = useContext(MediaContext) as TMedia;
    const { save } = useContext(MetaContext) as TMeta;
    const metaHasErrors = useScopeMetaErrors(state => state.hasErrors);

    const isImageOwner = useIsImageOwner(image);

    const onSave = async () => {
        if (isImageOwner === false) {
            notify('Error saving metadata', 'You are not the owner of this IRCODE');
            return;
        }
        const { results, errors } = validateMeta(meta.Meta);
        if (errors) {
            await notify('Information missing or invalid', 'Please fill in all required fields');
            onMetaSaveErrors(errors);
            return;
        }
        const confirmation = await confirm({
            title: 'Save Changes ',
            message: 'Are you sure you want to make updates to IRCODE?',
        });
        if (confirmation) {
            try {
                setShowLoading(true);

                // const image = imageFromImageOperation(imageOperation!);
                await save(image!.imageID, results!);

                // setMeta(setAllToIsEditing(meta, false));

                // If isAdd, reload as non-isAdd
                // if (isAdded) {
                //     // if (add) {
                //     //     goToParentPath();
                //     // }
                //     onPublish?.(image!.imageID);
                //     setIsPublished(true);

                //     // @ts-ignore
                //     if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.messageHandler) {
                //         // @ts-ignore
                //         window.webkit.messageHandlers.messageHandler.postMessage({
                //             "message": "close"
                //         });
                //     }
                //     // const newImageOperation = await getImage(image!.imageID);
                //     // setImageOperation(newImageOperation);
                // } else {

                // TODO: Refresh
                // refresh();

                await onUpdate();
                // }

                // publish(Event.RefreshImages);
                await notify('Success', 'Your IRCODE has been updated');
            } catch (error: any) {
                notify('Error saving metadata', error.message);
            } finally {
                setShowLoading(false);
            }
        }
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={
                {
                    // alignItems: 'flex-start',
                }
            }
        >
            <Button
                disabled={metaHasErrors}
                variant="irdbGradient"
                onClick={async () => {
                    onSave();
                    // await saveMeta(image.imageID, meta.Meta);
                    // refresh();
                }}
                sx={{
                    width: '30%',
                    // width: 'auto',
                }}
            >
                <i className="fa-light fa-check"></i>Save Changes
            </Button>
            <Button
                variant="irdbText"
                onClick={async () => {
                    if (
                        await confirm({
                            title: 'IRCODE',
                            message: 'Are you sure you want to delete this IRCODE?',
                        })
                    ) {
                        await remove(image.imageID);
                        navigate('/dashboard/myircodes');
                    }
                }}
            >
                Delete IRCODE
            </Button>
        </Stack>
    );
}
