import { useContext, useState } from 'react';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import MediaContext, { ImageOperation, isImageOwnedByUser, Query, TMedia, Upload } from 'src/contexts/MediaContext';
import { Image } from '../../types/Image';
import UserContext, { TUser } from 'src/contexts/UserContext';
import Cropper, { CropperProps } from './Cropper';
import IRCodeOwnedDialog from './IRCodeOwnedDialog';

type CropperOnSuccessProps =
    | { upload: true; onSuccess: (imageOperation: ImageOperation<Upload>) => void }
    | { upload?: false; onSuccess: (imageOperation: ImageOperation<Query>) => void };

interface CropperExtendedBaseProps extends Omit<CropperProps, 'onConfirm'> {
    onConfirm?: (imageOperation: ImageOperation<any>) => void;
    onImageAlreadyExists?: (image: Image) => void;
    handleViewExisting?: (imageOperation: ImageOperation<Query>) => void;
    onProgress: (progress: ImageOperation<any>) => void;
}

export type CropperExtendedProps = CropperExtendedBaseProps & CropperOnSuccessProps;

const CropperExtended = ({
    imageOperation,
    onConfirm,
    handleViewExisting,
    upload: shouldUpload,
    onProgress,
    onSuccess,
    onImageAlreadyExists,
    ...rest
}: CropperExtendedProps) => {
    const [ownedImageOperation, setOwnedImageOperation] = useState<ImageOperation<Query>>();
    const { foveate, query, upload } = useContext(MediaContext) as TMedia;
    const { setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { user } = useContext(UserContext) as TUser;
    const ownedImage = ownedImageOperation?.operation.Results?.Image;
    const isOwnedByUser = isImageOwnedByUser(ownedImage, user);
    const handleExisting = handleViewExisting && (() => handleViewExisting?.(ownedImageOperation!));

    return (
        <>
            <Cropper
                {...rest}
                imageOperation={imageOperation}
                onConfirm={async i => {
                    onConfirm?.(i);
                    setShowLoading(true);
                    try {
                        const f = foveate(i);
                        const foveated = await f.promise;
                        onProgress(foveated);
                        const q = query(foveated, onProgress);
                        const queried = (await q.promise) as ImageOperation<Query>;
                        if (queried.operation.ErrorMessage) {
                            console.error(queried.operation.ErrorMessage);
                        }
                        if (queried.operation.Results?.ImageAlreadyExists) {
                            const image = queried.operation.Results.Image;
                            if (image) {
                                onImageAlreadyExists?.(image);
                            }
                            const isOwnedByUser = isImageOwnedByUser(image, user);
                            const ioImage = imageOperation?.operation.Results?.Image as Image | undefined;
                            if (!isOwnedByUser || (ioImage && ioImage.imageID !== image?.imageID)) {
                                setShowLoading(false);
                                setOwnedImageOperation(queried);
                                return;
                            }
                        }
                        if (shouldUpload) {
                            const u = upload(queried, onProgress);
                            const uploaded = (await u.promise) as ImageOperation<Upload>;
                            onSuccess(uploaded);
                        } else {
                            onSuccess(queried);
                        }
                    } catch (error) {
                        console.error(error);
                    } finally {
                        setShowLoading(false);
                    }
                }}
            />
            <IRCodeOwnedDialog
                open={!!ownedImage}
                image={ownedImage}
                ownedByUser={isOwnedByUser}
                onCancel={() => setOwnedImageOperation(undefined)}
                handleViewExisting={handleExisting}
            />
        </>
    );
};

export default CropperExtended;
