import * as React from 'react';
import { Box, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { Color } from '../../../Color';
import UserContext, { TUser } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Chip, Type } from '../../general/Chip';
import PageHeader from './PageHeader';
import Title from './Title';
import { HomeStats, PagedResults } from '../../../contexts/MediaContext';
import { Image } from '../../../types/Image';
import useAuth from '../../../hooks/useAuth';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import EnterpriseContext, { Campaign, TEnterprise } from '../../../contexts/EnterpriseContext';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import Campaigns from './Campaigns';
import usePageTitle from 'src/hooks/usePageTitle';

const OverviewCard = ({ title, children }: { title: string; children?: React.ReactNode }) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <Card
            sx={{
                // display: { xs: 'none', sm: 'block' },
                p: 4,
                borderRadius: 2,
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
            }}
            elevation={0}
        >
            <Stack direction="column" spacing={4}>
                <Title>{title}</Title>
                {children}
            </Stack>
        </Card>
    );
};

// TODO: Move to number.ts
function abbreviateNumber(num: number): string {
    if (num < 1000) return num.toString();
    const units = ['k', 'M', 'B', 'T'];
    let unitIndex = -1;

    while (Math.abs(num) >= 1000 && unitIndex < units.length - 1) {
        unitIndex++;
        num /= 1000;
    }

    return `${num.toFixed(1)}${units[unitIndex]}`;
}

interface ChartProps {
    title: string;
    total: number;
    change: number;
    data: { key: string; value: number }[];
    colors?: { light: string; dark: string };
}

const Chart = ({ title, total, change, data, colors }: ChartProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const stackRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [chartHeight, setChartHeight] = useState(80);
    useEffect(() => {
        if (stackRef.current) {
            const layout = stackRef.current?.getBoundingClientRect();
            // console.log(layout);
            setChartHeight(layout.height);
            setIsLoading(false);
        }
    }, []);

    change = isNaN(change) ? 0 : change;

    return (
        <Stack direction="row" spacing={2}>
            <Box style={{ width: '50%' }}>
                <Stack
                    ref={stackRef}
                    direction="column"
                    spacing={1}
                    // onLayout={(event, children) => {
                    // }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            letterSpacing: '0.15em',
                            textAlign: 'left',
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            textTransform: 'uppercase',
                        }}
                        // style={{ textTransform: 'uppercase' }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nocturne Serif',
                            fontSize: '32px',
                            fontWeight: 600,
                            lineHeight: '40px',
                            letterSpacing: '-0.03em',
                            textAlign: 'left',
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        {abbreviateNumber(total)}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '18px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            color:
                                change > 0 ? Color.AnalyticsGreen
                                : change < 0 ? Color.AnalyticsRed
                                : darkMode ? Color.White
                                : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        {change > 0 ? '+' : ''}
                        {change.toFixed(0)}%
                    </Typography>
                </Stack>
            </Box>
            <Box style={{ height: chartHeight, width: '50%' }}>
                {isLoading ?
                    <CircularProgress />
                :   <ResponsiveBar
                        data={data}
                        keys={['value']}
                        indexBy="key"
                        borderRadius={4}
                        enableGridX={false} // Disable horizontal grid lines
                        enableGridY={false} // Disable vertical grid lines
                        isInteractive={false}
                        label=""
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={bar => {
                            if (bar.index === data.length - 1) {
                                return colors?.dark ?? 'gray';
                            } else {
                                return colors?.light ?? 'gray';
                            }
                        }}
                        padding={0.2}
                        layout="vertical"
                    />
                }
            </Box>
        </Stack>
    );
};

interface UpcomingProps {
    amount: string;
    interval: string;
}

const Upcoming = ({ amount, interval }: UpcomingProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <Stack direction="row" spacing={2}>
            <Box
                position="relative"
                sx={{
                    flexShrink: 0,
                    width: 80,
                    height: 80,
                    borderRadius: 2,
                    overflow: 'hidden',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        // blur:
                        // TODO: Add a blur filter
                        filter: 'blur(2px)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1,
                    }}
                    src="https://picsum.photos/80"
                    alt=""
                />
                <Stack
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '4px',
                        // bgcolor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '32px',
                            fontWeight: 400,
                            lineHeight: '28px',
                            letterSpacing: '0.01em',
                            textAlign: 'center',
                            color: '#FFFFFF',
                        }}
                    >
                        {amount}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            letterSpacing: '0.15em',
                            textAlign: 'left',
                            color: '#FFFFFF',
                            textTransform: 'uppercase',
                        }}
                    >
                        {interval}
                    </Typography>
                </Stack>
            </Box>
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    // justifyContent: 'left',
                    // alignContent: 'left',
                    alignItems: 'start',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                    }}
                >
                    Secure & Verify (Queen’s Crown)
                </Typography>
                <Chip label="Event" type={Type.Warning} />
            </Stack>
        </Stack>
    );
};

const TopIrcode = ({ image }: { image: Image }) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    return (
        <Stack direction="row" spacing={2}>
            <Box
                component="img"
                sx={{
                    flexShrink: 0,
                    width: 64,
                    height: 64,
                    borderRadius: 2,
                    overflow: 'hidden',
                    // objectFit: 'cover',
                    objectFit: 'contain',
                }}
                src={image.imageUrl}
                alt=""
            />
            <Stack direction="column">
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                    }}
                >
                    {image.metaContent?.title ?? 'Untitled'}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        letterSpacing: '0.15em',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        color: darkMode ? Color.White : Color.Black,
                    }}
                >
                    {abbreviateNumber(image.countViews)} Views
                </Typography>
            </Stack>
        </Stack>
    );
};

export default function EnterpriseOverview() {
    const navigate = useNavigate();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;
    const { fetch } = useContext(EnterpriseContext) as TEnterprise;

    const [isProcessing, setIsProcessing] = useState(false);
    const [homeStats, setHomeStats] = useState<HomeStats>();
    // TODO: Move "Your top IRCODES" to stats
    const [images, setImages] = useState<Image[]>([]);
    const [campaigns, setCampaigns] = useState<PagedResults<Campaign[]>>();

    useAuth(() => {
        fetch()
            .then(campaigns => {
                // setHomeStats(data.stats);
                // setImages(data.images);
                setCampaigns(campaigns);
            })
            .catch(error => {
                console.error(error);
            });
        // console.log('Overview > useAuth');
        // if (user?.userID === undefined) {
        //     return;
        // }
        // console.log('user.userID', user.userID);

        // stats()
        //     .then(stats => {
        //         setHomeStats(stats);
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     })
        //     .finally(() => {
        //         // setIsLoading(false);
        //     });
        // upcoming()
        //     .then(stats => {
        //         // setHomeStats(stats);
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     })
        //     .finally(() => {
        //         // setIsLoading(false);
        //     });

        // // TODO: Temporary until there's a "My Top IRCODEs" API
        // fetchImages(user.userID, 0)
        //     .then((images) => {
        //         setImages(images.Results.slice(0, 3));
        //         // setPageCount(images.Pages);
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     })
        //     .finally(() => {
        //         // setIsLoading(false);
        //     });
    }, [user]);

    useEffect(() => {
        console.log('Overview mounting');
        return () => {
            console.log('Overview unmounting');
        };
    }, []);

    function calculatePercentageChange(arr: number[]): number {
        if (arr.length < 2) {
            return 0;
        }

        const lastElement = arr[arr.length - 1];
        const secondToLastElement = arr[arr.length - 2];

        if (secondToLastElement === 0) {
            return 100;
        }

        return ((lastElement - secondToLastElement) / secondToLastElement) * 100;
    }

    // useEffect(() => {
    //     // if (pageState && pageState.mobileOpen !== undefined) {
    //     //     setMobileOpen(pageState.mobileOpen);
    //     // }
    // }, []);

    usePageTitle('Enterprise Overview');

    return (
        <Stack
            direction="column"
            spacing={4}
            sx={{
                alignContent: 'flex-start',
                p: 4,
            }}
        >
            <PageHeader title="Campaigns" />

            {!isProcessing && (
                <Grid
                    container
                    // xs={12}
                >
                    <Grid item container spacing={4} direction="row" xs={12}>
                        {/* <Grid item xs={12} md={6} lg={4}>
                            <OverviewCard title="Weekly Views">
                                <Stack spacing={4}>
                                    <Chart
                                        title="Views"
                                        total={homeStats?.TotalViews ?? 0}
                                        change={calculatePercentageChange((homeStats?.TotalBars ?? []).map(stat => stat.value))}
                                        data={homeStats?.TotalBars ?? []}
                                        colors={{ light: '#A0D7E7', dark: '#188BD8' }}
                                    />
                                    <Divider />
                                    <Chart
                                        title="Unique Views"
                                        total={homeStats?.UniqueViews ?? 0}
                                        change={calculatePercentageChange((homeStats?.UniqueBars ?? []).map(stat => stat.value))}
                                        data={homeStats?.UniqueBars ?? []}
                                        colors={{ light: '#CFC8FF', dark: '#AA79E7' }}
                                    />
                                </Stack>
                            </OverviewCard>
                        </Grid> */}
                        {/* <Grid item xs={12} md={6} lg={4}>
                            <OverviewCard title="Top this week">
                                <Stack spacing={4}>
                                    {
                                        (homeStats?.TopViews ?? []).map((stat, index) => {
                                            return (
                                                <Box
                                                    key={index}
                                                    onClick={() => {
                                                        navigate(`/dashboard/ircodes/${stat.imageID}`)
                                                    }}
                                                >
                                                    <TopIrcode image={stat} />
                                                </Box>
                                            );
                                        })
                                    }
                                </Stack>
                            </OverviewCard>
                        </Grid> */}
                        {/* <Grid item xs={12} md={6} lg={4}>
                            <OverviewCard title="Upcoming">
                                <Stack spacing={4}>
                                    <Upcoming amount="4" interval="Days" />
                                    <Upcoming amount="2" interval="Weeks" />
                                    <Divider />
                                    <Button variant="irdbText">Learn More<i className="fa-light fa-arrow-right" style={{ marginLeft: 4 }}></i></Button>
                                </Stack>
                            </OverviewCard>
                        </Grid> */}
                    </Grid>
                </Grid>
            )}

            <Stack
                spacing={2}
                sx={{
                    // display: { xs: 'none', sm: 'block' },
                    p: 4,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                }}
            >
                {/* <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    <Title>Campaigns</Title>
                    <Button
                        variant="irdbGradient"
                        sx={{
                            width: 200,
                        }}
                        onClick={() => {
                            navigateAndScrollToTop('/enterprise/campaigns/new');
                        }}
                    >
                        Create a Campaign
                    </Button>
                </Stack> */}
                {isProcessing && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <Campaigns />
                {/* { (campaigns?.Results ?? []).map((campaign) => {
                    return (
                        <MyCampaignRow
                            key={campaign.campaignID}
                            campaign={campaign}
                        />
                    );
                })} */}
            </Stack>

            {/* {!isProcessing && (
                <Grid
                    item
                    xs={12}
                >
                    <OverviewCard title="Your top IRCODES">
                        { isProcessing && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                        { (images ?? []).map((image) => {
                            return <OwnedIrcodeRow
                                key={image.imageID}
                                image={image}
                                showCheckbox={false}
                                onEdit={() => {
                                    navigateAndScrollToTop(`/enterprise/campaigns/${image.campaignID}/ircodes/${image.imageID}#information`);
                                }}
                            />;
                        })}
                    </OverviewCard>
                </Grid>
            )} */}
        </Stack>
    );
}
