import { useCallback, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Color } from 'src/Color';
import { nullUndefinedOrEmpty } from 'src/util/string';
import useAnalytics, { Event, Parameter, QueryResult, Type } from 'src/hooks/useKeenAnalytics';
import SortableTable from '../../../../general/SortableTable';
import { Source, Timeframe } from 'src/types/Analytics';

interface Props {
    source: Source;
    campaignID: number;
    timeframe?: Timeframe;
}

export default function ClickChart({ campaignID, timeframe }: Props) {
    interface Click {
        link_title: string;
        link_url: string;
        result: number;
    }

    interface Data {
        [key: string]: any;
    }

    const { query } = useAnalytics();

    const [clicks, setClicks] = useState<Click[]>([]);

    const fetchAnalytics = useCallback(() => {
        return new Promise<Click[]>((resolve, reject) => {
            query(
                // Type.CountUnique,
                Type.Count,
                {
                    event_collection: Event.ImageLinkOpened,
                    timeframe,
                    filters: [
                        {
                            property_name: Parameter.CampaignID,
                            operator: 'eq',
                            property_value: campaignID,
                        },
                    ],
                    group_by: ['link_title', 'link_url'],
                },
            )
                .then((results: QueryResult<any>[]) => {
                    resolve(
                        results
                            .map((click: any, index: number) => {
                                if (!nullUndefinedOrEmpty(click.link_url) && !/^https?:\/\//i.test(click.link_url)) {
                                    click.link_url = 'https://' + click.link_url;
                                }
                                return {
                                    ...click,
                                    link_title:
                                        click.link_title.replace('&nbsp;', ' ') ||
                                        new URL(click.link_url).hostname.replace(/^www\./, ''),
                                };
                            })
                            .sort((a: Click, b: Click) => b.result - a.result),
                    );
                })
                .catch((error: any) => {
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, query, timeframe]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        fetchAnalytics()
            .then(newClicks => {
                setClicks(newClicks);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [fetchAnalytics, campaignID]);

    const columns = [
        { label: 'Links', sortValue: 'link_title' },
        { label: 'Clicks', sortValue: 'result' },
    ];

    const customCellRenderers: { [key: string]: (row: Data) => React.ReactNode } = {
        Links: row => (
            <span
                style={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                onClick={() => {
                    window.open(row.link_url, '_blank');
                }}
            >
                {row.link_title}&nbsp;
                <i
                    className="fa-regular fa-arrow-up-right-from-square"
                    style={{
                        color: Color.Purple,
                    }}
                />
            </span>
        ),
        Clicks: row => row.result,
    };

    return (
        <Stack
            direction="column"
            sx={
                {
                    // backgroundColor: Color.LightLavenderDarkMode
                }
            }
        >
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                    mb: 2,
                }}
            >
                Clicks
            </Typography>

            <SortableTable columns={columns} rows={clicks} cellRenderers={customCellRenderers} overflowCount={4} />
        </Stack>
    );
}
