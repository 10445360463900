// 1000 * 60 * 60 * 24
const DAY_IN_MS = 86400000;
// 1000 * 60 * 60
const HOUR_IN_MS = 3600000;
// 1000 * 60
const MINUTE_IN_MS = 60000;
const SECOND_IN_MS = 1000;

class Timespan {
    days!: number;
    hours!: number;
    minutes!: number;
    seconds!: number;
    ms!: number;

    private _totalMs!: number;
    public get totalMs(): number {
        return this._totalMs;
    }

    constructor(ms: number) {
        this.update = this.update.bind(this);
        this.equals = this.equals.bind(this);
        this.toTimespanString = this.toTimespanString.bind(this);
        this.update(ms);
    }

    public equals(other: Timespan): boolean {
        return this.totalMs === other.totalMs;
    }

    public update(ms: number): Timespan {
        if (ms < 0) {
            console.warn('Timespan.update: ms cannot be negative. Falling back to 0.');
            ms = 0;
        }
        this._totalMs = ms;

        this.days = Math.floor(ms / DAY_IN_MS);
        ms -= this.days * DAY_IN_MS;

        this.hours = Math.floor(ms / HOUR_IN_MS);
        ms -= this.hours * HOUR_IN_MS;

        this.minutes = Math.floor(ms / MINUTE_IN_MS);
        ms -= this.minutes * MINUTE_IN_MS;

        this.seconds = Math.floor(ms / SECOND_IN_MS);
        ms -= this.seconds * SECOND_IN_MS;

        this.ms = ms;

        return this;
    }

    public toTimespanString(): string {
        let result = '';
        if (this.days > 0) {
            result += `${this.days}d `;
        }
        if (this.hours > 0) {
            result += `${this.hours}h `;
        }
        if (this.minutes > 0) {
            result += `${this.minutes}m `;
        }
        if (this.seconds > 0) {
            result += `${this.seconds}s `;
        } else {
            result += '1s';
        }
        return result.trim();
    }
}

export default Timespan;
