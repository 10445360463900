import useApi, { Method } from 'src/hooks/useApi';
import { ImageID, Image as IrdbImage } from '../../types/Image';

export interface TFetchImage {
    fetchImage: (imageId: ImageID) => Promise<IrdbImage>;
}

const useFetchImage = (): TFetchImage => {
    const { request } = useApi();

    const fetchImage = async (imageId: ImageID): Promise<IrdbImage> => {
        const response = await request({
            method: Method.GET,
            path: `/Images/${imageId}`,
        });
        // console.log("response", response);

        return response.data.Results.Image;
    };

    return {
        fetchImage,
    };
};

export default useFetchImage;
