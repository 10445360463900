import * as React from 'react';
import { Stack } from '@mui/material';
import BulkUploader from '../uploader/BulkUploader';
import { useContext, useState } from 'react';
import MediaContext, { PagedResults, TMedia } from '../../../contexts/MediaContext';
import { Image } from '../../../types/Image';
import PageHeader from './PageHeader';
import UserContext, { TUser, UserType } from '../../../contexts/UserContext';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import MetaContext, { TMeta } from '../../../contexts/MetaContext';
import OwnedIrcodes from './OwnedIrcodes';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import EnterpriseContext, { TEnterprise } from 'src/contexts/EnterpriseContext';
import { ImageSortByField, ImageSortOrderField } from '../../../types/Image';
import usePageTitle from 'src/hooks/usePageTitle';

export default function MyIrcodes() {
    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { darkMode, isMobile } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;
    const { fetchImages: fetchUserImages, remove } = useContext(MediaContext) as TMedia;
    const { search } = useContext(MetaContext) as TMeta;
    const { fetch: fetchEnterpriseCampaigns, addImage } = useContext(EnterpriseContext) as TEnterprise;

    const fetchImages = (
        page: number,
        limit: number,
        sortBy: ImageSortByField,
        sortOrder: ImageSortOrderField,
        hideCampaigns: boolean,
    ): Promise<PagedResults<Image[]>> => {
        return new Promise((resolve, reject) => {
            if (user?.userID === undefined) {
                reject('User not found');
            }

            fetchUserImages(user!.userID!, page, limit, sortBy, sortOrder, hideCampaigns)
                .then(results => {
                    resolve(results);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    const fetchCampaigns =
        user?.userType === UserType.Enterprise ?
            async () => {
                const campaigns = await fetchEnterpriseCampaigns();
                return campaigns;
            }
        :   undefined;
    const addImageToCampaign =
        user?.userType === UserType.Enterprise ?
            async (campaignID: number, imageID: string) => {
                return addImage(campaignID, imageID);
            }
        :   undefined;

    const [isProcessing, setIsProcessing] = useState(false);

    usePageTitle('My IRCODES');

    return (
        <Stack
            direction="column"
            spacing={4}
            sx={{
                p: 4,
            }}
        >
            <PageHeader title="My IRCODES" />

            <BulkUploader
                onStart={() => {
                    setIsProcessing(true);
                }}
                onComplete={() => {
                    setIsProcessing(false);
                }}
            />

            <OwnedIrcodes
                isProcessing={isProcessing}
                fetchImages={fetchImages}
                fetchCampaigns={fetchCampaigns}
                addImageToCampaign={addImageToCampaign}
                search={search}
                onEdit={(imageId: string) => {
                    navigateAndScrollToTop(`/dashboard/myircodes/${imageId}#information`);
                }}
                remove={remove}
            />
        </Stack>
    );
}
