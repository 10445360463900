import { ElementType, useContext } from 'react';
import MuiCard, { CardProps } from '@mui/material/Card';
import { Color } from 'src/Color';
import ThemeContext from 'src/contexts/ThemeContext';

const Card = <T extends ElementType>({ sx, ...props }: { component?: T } & CardProps<T>) => {
    const { darkMode } = useContext(ThemeContext)!;
    return (
        <MuiCard
            elevation={0}
            sx={{
                py: '1rem',
                px: '1.5rem',
                borderRadius: '0.75rem',
                background: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                color: darkMode ? Color.White : Color.Black,
                ...sx,
            }}
            {...props}
        />
    );
};

export default Card;
