import { useStore as useZustandStore, StoreApi, UseBoundStore, StateCreator } from 'zustand';
import { ArrayMember } from 'src/types/util';
import { StoreMutatorIdentifier } from 'zustand/vanilla';

/** Infers the state type from StoreApi */
type ExtractState<S> =
    S extends (
        {
            getState: () => infer T;
        }
    ) ?
        T
    :   never;

/** Zustand store selector */
export type Selector<T extends { getState: () => unknown }, R> = (store: ExtractState<T>) => R;

/** Strips the imperative access methods from StoreApi */
export type StoreHook<T extends { getState: () => unknown }> = {
    (selector?: never): ExtractState<T>;
    <R>(selector?: Selector<T, R>): R;
};

/** Abstracts the zustand store api to a hook that accepts selectors and can be used in components */
export function createStoreHook<T extends StoreApi<object>>(store: T): StoreHook<T> {
    return <R = unknown>(selector?: Selector<T, R>) => {
        return useZustandStore(store, selector!);
    };
}

type AutoSelectors<T> = { [K in keyof T]: () => T[K] };
/** Object with auto selectors property */
type SelectorsObject<T> = { use: AutoSelectors<T> };
/** Store hook with auto selectors */
type SelectorsHook<T> = StoreHook<StoreApi<T>> & AutoSelectors<T>;
/** Store with auto selectors */
type WithSelectors<S extends { getState: () => unknown }> = S & SelectorsObject<ExtractState<S>>;
/** Store with auto selectors in external object */
type WithSelectorTarget<S extends { getState: () => unknown }, T> = T & SelectorsObject<ExtractState<S>>;
/** Store hook with auto selectors */
export type StoreHookWithSelectors<S extends { getState: () => unknown }> = S & { use: SelectorsHook<ExtractState<S>> };

// Note: creating auto selectors removes the need to create and memoize selectors in components
/** Creates auto selectors for a store and assigns them to the store object */
function createStoreSelectors<S extends UseBoundStore<StoreApi<object>>>(store: S): WithSelectors<S>;
/** Creates auto selectors for a store and assigns them to the target object */
function createStoreSelectors<S extends UseBoundStore<StoreApi<object>>, T>(
    store: S,
    target: T,
): WithSelectorTarget<S, T>;
function createStoreSelectors<S extends UseBoundStore<StoreApi<object>>, T>(
    _store: S,
    target?: T,
): WithSelectors<S> | WithSelectorTarget<S, T> {
    type StateType = ExtractState<S>;
    type SelectorsObjectUse = SelectorsObject<StateType>['use'];
    let store: WithSelectors<S> | WithSelectorTarget<S, T>;
    if (target) {
        store = target as WithSelectorTarget<S, T>;
    } else {
        store = _store as WithSelectors<S>;
    }
    store.use = {} as AutoSelectors<ExtractState<S>>;
    for (const k of Object.keys(_store.getState()) as Array<keyof StateType>) {
        (store.use as SelectorsObjectUse)[k as keyof SelectorsObjectUse] = () => _store(s => (s as StateType)[k]);
    }

    return store;
}

export { createStoreSelectors };

export function createVanillaStoreSelectors<S extends StoreApi<object>>(_store: S): StoreHookWithSelectors<S> {
    type StateType = ExtractState<S>;
    type SelectorsObjectUse = SelectorsObject<StateType>['use'];
    type StoreHookUse = StoreHookWithSelectors<S>['use'];
    const store = _store as StoreHookWithSelectors<S>;
    store.use = createStoreHook(store) as StoreHookUse;
    for (const k of Object.keys(store.getState()) as Array<keyof StateType>) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        (store.use as SelectorsObjectUse)[k as keyof SelectorsObjectUse] = () => store.use(s => s[k]);
    }
    return store;
}

export function createSelectors<T extends Record<PropertyKey, any>>() {
    return <P extends (keyof T)[]>(...properties: P) => {
        return properties.reduce(
            (acc, prop) => {
                acc[prop as ArrayMember<P>] = (state: T) => state[prop] as T[ArrayMember<P>];
                return acc;
            },
            {} as {
                [K in keyof Pick<T, ArrayMember<P>>]: Selector<StoreApi<T>, T[K]>;
            },
        );
    };
}

export type ComputedSelector<T, R> = (state: T, prevState: T) => R;

export function createComputedState<T, S>(
    baseState: T,
    storeApi: StoreApi<T & S>,
    selectors: { [K in keyof S]: ComputedSelector<T & Partial<S>, S[K]> },
    mutate = true,
): T & S {
    type R = T & S;
    const listeners: Array<[keyof S, ComputedSelector<T & Partial<S>, unknown>]> = [];
    const state = (mutate ? baseState : { ...baseState }) as R;
    const keys = Object.keys(selectors) as Array<keyof S>;
    for (const key of keys) {
        const selector = selectors[key];
        listeners.push([key, selector]);
        state[key as keyof R] = selector(state, state) as R[keyof R];
    }
    storeApi.subscribe((newState, prevState) => {
        let result: Partial<R> | null = null;
        for (let i = 0; i < listeners.length; i++) {
            const [key, listener] = listeners[i];
            const compareResult = listener(newState, prevState) as R[keyof S];
            if (compareResult !== prevState[key]) {
                result ??= {};
                result[key] = compareResult;
            }
        }
        if (result) {
            storeApi.setState(result);
        }
    });
    return state;
}

export type StateCreatorWithDependencies<
    T,
    Mis extends [StoreMutatorIdentifier, unknown][] = [],
    Mos extends [StoreMutatorIdentifier, unknown][] = [],
    U = T,
    D = unknown,
> = (...args: [...Parameters<StateCreator<T, Mis, Mos, U>>, D]) => ReturnType<StateCreator<T, Mis, Mos, U>>;
