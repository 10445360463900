import { Accordion, AccordionSummary, Typography, AccordionDetails, Divider, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { Image } from '../../types/Image';
import { metaFieldForMetaType, metaIncludes } from '../../contexts/MetaContext';
import { MetaField } from '../../types/MetaField';
import { MetaType, MetaContent } from '../../types/MetaTypes';
import { ArtistNameForm } from './ArtistName';
import { CardTypeForm } from './CardType';
import { CustomForm, Custom } from './Custom';
import { Description, DescriptionForm } from './Description';
import { DisplayLocationForm, DisplayLocation } from './DisplayLocation';
import { Email, EmailForm } from './Email';
import { GalleryNameForm, GalleryName } from './GalleryName';
import { Link, LinkForm } from './Link';
import { ProductLink } from './ProductLink';
import { MediumForm } from './Medium';
import { Phone, PhoneForm } from './Phone';
import { PriceForm, Price } from './Price';
import { ProvenanceForm, Provenance } from './Provenance';
import { SizeForm } from './Size';
import { TagsForm, Tags } from './Tags';
import { TitleForm } from './Title';
import { YearForm } from './Year';
import { nullUndefinedOrEmpty } from '../../util/string';
import PrivateIndicator from './PrivateIndicator';

const ArtCard = ({ meta }: { meta: MetaField[] }) => {
    const artistName = metaFieldForMetaType(meta, MetaType.ArtistName);
    const title = metaFieldForMetaType(meta, MetaType.Title);
    const year = metaFieldForMetaType(meta, MetaType.Year);
    const medium = metaFieldForMetaType(meta, MetaType.Medium);
    const size = metaFieldForMetaType(meta, MetaType.Size);

    const Detail = ({ detail }: { detail: string }) => {
        return (
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    color: 'gray',
                }}
            >
                {detail}
            </Typography>
        );
    };

    const details = [year?.metaContent.year, medium?.metaContent.medium, size?.metaContent.size]
        .filter(detail => !nullUndefinedOrEmpty(detail))
        .map((detail, index) => {
            return <Detail key={index} detail={detail} />;
        });

    return (
        <Stack
            direction="column"
            sx={{
                paddingX: 3,
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '40px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                }}
            >
                {artistName?.metaContent.name}
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '40px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                    fontStyle: 'italic',
                }}
            >
                {title?.metaContent.title ?? 'Untitled'}
            </Typography>
            <Stack
                direction="row"
                spacing={1}
                divider={<Detail detail="•" />}
                sx={{
                    marginTop: 2,
                }}
            >
                {details}
            </Stack>
        </Stack>
    );
};

export default function ArtCardStack({
    image,
    meta,
    isEditing,
    editingTypes = [
        MetaType.CardType,
        MetaType.ArtistName,
        MetaType.Title,
        MetaType.Year,
        MetaType.Medium,
        MetaType.Size,
        MetaType.Link,
        MetaType.Description,
        MetaType.Tags,
        MetaType.Price,
        MetaType.GalleryName,
        MetaType.DisplayLocation,
        MetaType.Provenance,
        MetaType.Email,
        MetaType.Phone,
        MetaType.Custom,
    ],
    onChange,
}: {
    image: Image;
    meta: MetaField[];
    isEditing: boolean;
    editingTypes?: MetaType[];
    onChange: (metaType: MetaType, metaContent: MetaContent) => void;
}) {
    const { confirm } = useContext(FeedbackContext) as TFeedback;

    const [showMore, setShowMore] = useState(false);

    if (isEditing) {
        return (
            <Stack direction="column" spacing={2}>
                {editingTypes.includes(MetaType.CardType) && (
                    <CardTypeForm
                        metaField={metaFieldForMetaType(meta, MetaType.CardType)}
                        onChange={async (metaContent: MetaContent) => {
                            if (
                                await confirm({
                                    title: 'Are your sure?',
                                    message: 'Some data may be lost',
                                    yes: 'Switch Templates',
                                    no: 'Cancel',
                                })
                            ) {
                                onChange(MetaType.CardType, metaContent);
                            }
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.ArtistName) && (
                    <ArtistNameForm
                        metaField={metaFieldForMetaType(meta, MetaType.ArtistName)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.ArtistName, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Title) && (
                    <TitleForm
                        metaField={metaFieldForMetaType(meta, MetaType.Title)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Title, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Year) && (
                    <YearForm
                        metaField={metaFieldForMetaType(meta, MetaType.Year)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Year, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Medium) && (
                    <MediumForm
                        metaField={metaFieldForMetaType(meta, MetaType.Medium)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Medium, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Size) && (
                    <SizeForm
                        metaField={metaFieldForMetaType(meta, MetaType.Size)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Size, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Link) && (
                    <LinkForm
                        metaField={metaFieldForMetaType(meta, MetaType.Link)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Link, metaContent);
                        }}
                    />
                )}
                {[
                    MetaType.Description,
                    MetaType.Tags,
                    MetaType.Price,
                    MetaType.GalleryName,
                    MetaType.DisplayLocation,
                    MetaType.Provenance,
                    MetaType.Email,
                    MetaType.Phone,
                ].some(metaType => editingTypes.includes(metaType)) && (
                    <Accordion
                        onChange={(event, expanded) => {
                            setShowMore(expanded);
                        }}
                        sx={{
                            background: 'transparent',
                            boxShadow: 'none',
                            '&:before': {
                                content: 'none',
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<i className="fa-solid fa-chevron-down"></i>}
                            sx={{
                                borderBottom: '1px solid rgba(255, 255, 255, .1)',
                                mx: 2,
                                '&.Mui-expanded': {
                                    minHeight: 0,
                                },
                                '& > div': {
                                    margin: '1em 0 !important',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.05em',
                                    textTransform: 'uppercase',
                                    '&.Mui-expanded': {
                                        minHeight: 0,
                                    },
                                }}
                            >
                                {showMore ? 'Show Less' : 'Show More'}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                padding: 0,
                                mt: 2,
                            }}
                        >
                            <Stack direction="column" spacing={2}>
                                {editingTypes.includes(MetaType.Description) && (
                                    <DescriptionForm
                                        metaField={metaFieldForMetaType(meta, MetaType.Description)}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.Description, metaContent);
                                        }}
                                    />
                                )}
                                {editingTypes.includes(MetaType.Tags) && (
                                    <TagsForm
                                        metaField={metaFieldForMetaType(meta, MetaType.Tags)}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.Tags, metaContent);
                                        }}
                                    />
                                )}
                                {editingTypes.includes(MetaType.Price) && (
                                    <PriceForm
                                        metaField={metaFieldForMetaType(meta, MetaType.Price)}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.Price, metaContent);
                                        }}
                                    />
                                )}
                                {editingTypes.includes(MetaType.GalleryName) && (
                                    <GalleryNameForm
                                        metaField={metaFieldForMetaType(meta, MetaType.GalleryName)}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.GalleryName, metaContent);
                                        }}
                                    />
                                )}
                                {editingTypes.includes(MetaType.DisplayLocation) && (
                                    <DisplayLocationForm
                                        metaField={metaFieldForMetaType(meta, MetaType.DisplayLocation)}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.DisplayLocation, metaContent);
                                        }}
                                    />
                                )}
                                {editingTypes.includes(MetaType.Provenance) && (
                                    <ProvenanceForm
                                        metaField={metaFieldForMetaType(meta, MetaType.Provenance)}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.Provenance, metaContent);
                                        }}
                                    />
                                )}
                                {editingTypes.includes(MetaType.Email) && (
                                    <EmailForm
                                        metaField={metaFieldForMetaType(meta, MetaType.Email)}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.Email, metaContent);
                                        }}
                                    />
                                )}
                                {editingTypes.includes(MetaType.Phone) && (
                                    <PhoneForm
                                        metaField={metaFieldForMetaType(meta, MetaType.Phone)}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.Phone, metaContent);
                                        }}
                                    />
                                )}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )}
                {editingTypes.includes(MetaType.Custom) && (
                    <CustomForm
                        metaField={metaFieldForMetaType(meta, MetaType.Custom)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Custom, metaContent);
                        }}
                    />
                )}
            </Stack>
        );
    } else {
        return (
            <Stack spacing={2} divider={<Divider />}>
                <Stack
                // In WebApp, the meta components have the spacing. Maybe fix.
                // spacing={2}
                >
                    <ArtCard meta={meta} />
                    <PrivateIndicator mt={4} image={image} />
                    <Link image={image} metaField={metaFieldForMetaType(meta, MetaType.Link)} />
                    <ProductLink image={image} metaField={metaFieldForMetaType(meta, MetaType.ProductLink)} />
                </Stack>
                {metaIncludes(meta, [
                    MetaType.Description,
                    MetaType.Price,
                    MetaType.GalleryName,
                    MetaType.DisplayLocation,
                    MetaType.Provenance,
                    MetaType.Email,
                    MetaType.Phone,
                    MetaType.Tags,
                    MetaType.Custom,
                ]) && (
                    <Stack
                    // In WebApp, the meta components have the spacing. Maybe fix.
                    // spacing={2}
                    >
                        <Description image={image} metaField={metaFieldForMetaType(meta, MetaType.Description)} />
                        <Price image={image} metaField={metaFieldForMetaType(meta, MetaType.Price)} />
                        <GalleryName image={image} metaField={metaFieldForMetaType(meta, MetaType.GalleryName)} />
                        <DisplayLocation
                            image={image}
                            metaField={metaFieldForMetaType(meta, MetaType.DisplayLocation)}
                        />
                        <Provenance image={image} metaField={metaFieldForMetaType(meta, MetaType.Provenance)} />
                        <Email image={image} metaField={metaFieldForMetaType(meta, MetaType.Email)} />
                        <Phone image={image} metaField={metaFieldForMetaType(meta, MetaType.Phone)} />
                        <Tags image={image} metaField={metaFieldForMetaType(meta, MetaType.Tags)} />
                        <Custom image={image} metaField={metaFieldForMetaType(meta, MetaType.Custom)} />
                    </Stack>
                )}
            </Stack>
        );
    }
}
