import { Checkbox, Box, Typography, TableCell, Select, MenuItem, SelectChangeEvent, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '../../general/IconButton';
import { useContext } from 'react';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import { MetaContent, MetaType } from '../../../types/MetaTypes';
import EnterpriseContext, { Campaign, DefaultCampaignImageUrl, TEnterprise } from '../../../contexts/EnterpriseContext';
import EventContext, { TEvent, Event } from 'src/contexts/EventContext';

interface IrcodeImageProps {
    campaign: Campaign;
}

const IrcodeImage = ({ campaign }: IrcodeImageProps) => {
    // let dimImage = false;
    // if (
    //     (image.imageStatus === 'draft') || transferInProcess(image)
    // ) {
    //     dimImage = true;
    // }

    return (
        <Box
            sx={{
                position: 'relative',
                flexShrink: 0,
                width: { xs: '100%', sm: 140 },
                height: { xs: 200, sm: 140 },
                overflow: 'hidden',
            }}
        >
            <Box
                component="img"
                src={campaign.campaignImageUrl ?? DefaultCampaignImageUrl}
                alt=""
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: { xs: '100%', sm: 140 },
                    height: { xs: 200, sm: 140 },
                    objectFit: 'contain',
                }}
            />
        </Box>
    );
};

interface Props {
    campaign: Campaign;
    showCheckbox?: boolean;
    isSelected?: boolean;
    onSelected?: (selected: boolean) => void;
    isEditing?: boolean;
    editingTypes?: MetaType[];
    copyOnConfirm?: (metaType: MetaType, metaContent: MetaContent) => void;
    onChange?: (metaType: MetaType, metaContent: MetaContent) => void;
    onMembersClick?: (campaign: Campaign) => void;
    onDeleted?: () => void;
}

export default function MyCampaignRow({
    campaign,
    showCheckbox = false,
    isSelected = false,
    onSelected,
    isEditing = false,
    editingTypes = [],
    copyOnConfirm,
    onChange,
    onMembersClick,
    onDeleted,
}: Props) {
    const navigate = useNavigate();
    const { isMobile, darkMode } = useContext(ThemeContext) as TTheme;
    const { setSuccessMessage, setErrorMessage, confirm } = useContext(FeedbackContext) as TFeedback;
    const { deleteCampaign, removeCampaignImages, update } = useContext(EnterpriseContext) as TEnterprise;
    const { publish } = useContext(EventContext) as TEvent;

    const formatDate = (unixTimestamp?: number) => {
        if (unixTimestamp === undefined) {
            return 'Invalid date';
        }
        const notificationDate = new Date(unixTimestamp * 1000);
        const currentDate = new Date();
        notificationDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        if (notificationDate.getTime() === currentDate.getTime()) {
            return 'TODAY';
        }

        const isCurrentYear = notificationDate.getFullYear() === currentDate.getFullYear();
        const dateOptions: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
        const formattedDate = notificationDate.toLocaleDateString(undefined, dateOptions);

        return isCurrentYear ? `${formattedDate}, ${notificationDate.getFullYear()}` : formattedDate;
    };

    const handleStatusChange = async (event: SelectChangeEvent<string>) => {
        const newStatus = event.target.value;
        const confirmationMessage =
            newStatus === 'publish' ? 'Want to publish this campaign?' : 'Save this campaign as a draft';
        if (
            await confirm({
                title: 'Change Campaign Status',
                message: confirmationMessage,
                yes: 'Yes',
                no: 'No',
                destructive: true,
            })
        ) {
            try {
                await update({ ...campaign, campaignStatus: newStatus });
                setSuccessMessage('Campaign status updated successfully');
                publish(Event.RefreshCampaigns);
            } catch (error) {
                setErrorMessage('Failed to update campaign status');
            }
        }
    };

    return (
        <TableRow
            key={campaign.campaignID}
            sx={{
                height: { xs: 'auto', sm: isEditing ? 'auto' : 140 },
                position: 'relative',
                textAlign: 'left',
                '&:hover': {
                    backgroundColor: darkMode ? Color.LightLavenderLightMode : Color.LightLavenderDarkMode,
                },
            }}
        >
            <TableCell>
                {showCheckbox && (
                    <Checkbox
                        checked={isSelected}
                        onChange={e => {
                            e.stopPropagation();
                            if (onSelected) {
                                onSelected(e.target.checked);
                            }
                        }}
                    />
                )}
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        onClick={event => {
                            event.stopPropagation();
                            navigate(`/enterprise/campaigns/${campaign.campaignID}#analytics`);
                        }}
                    >
                        <IrcodeImage campaign={campaign} />
                    </Box>
                    {!isEditing && (
                        <Box sx={{ flexGrow: 1, paddingX: 2 }}>
                            <Typography>{campaign.campaignName}</Typography>
                            <Box
                                className="campaign-row-buttons"
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    paddingTop: 1,
                                }}
                            >
                                <IconButton
                                    icon="fa-chart-line"
                                    tooltip="Analytics"
                                    onClick={event => {
                                        event.stopPropagation();
                                        navigate(`/enterprise/campaigns/${campaign.campaignID}#analytics`);
                                    }}
                                />
                                <IconButton
                                    icon="fa-pen"
                                    tooltip="Edit"
                                    onClick={event => {
                                        event.stopPropagation();
                                        navigate(`/enterprise/campaigns/${campaign.campaignID}#ircodes`);
                                    }}
                                />
                                <IconButton
                                    icon="fa-trash"
                                    tooltip="Delete"
                                    onClick={async event => {
                                        event.stopPropagation();
                                        if (
                                            await confirm({
                                                title: 'Delete Campaign',
                                                message: 'Are you sure you want to delete this campaign?',
                                                yes: 'Delete',
                                                no: 'Cancel',
                                                destructive: true,
                                            })
                                        ) {
                                            if (
                                                campaign.media &&
                                                (await confirm({
                                                    title: 'Delete Campaign IRCODES?',
                                                    message:
                                                        'Do you want to also delete all IRCODES you owned that were in this campaign?',
                                                    yes: 'Yes, Delete IRCODES',
                                                    no: 'No, Keep IRCODES',
                                                    destructive: true,
                                                }))
                                            ) {
                                                await removeCampaignImages(campaign.campaignID);
                                            }
                                            try {
                                                await deleteCampaign(campaign.campaignID);
                                                onDeleted?.();
                                                setSuccessMessage('Campaign deleted successfully');
                                            } catch (error) {
                                                setErrorMessage('Failed to delete campaign');
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </TableCell>
            <TableCell>
                <Typography>{campaign.user?.[0]?.userName || ''}</Typography>
            </TableCell>
            <TableCell>
                <Select
                    value={campaign.campaignStatus || ''}
                    onChange={handleStatusChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Campaign Status' }}
                    sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                >
                    <MenuItem value="publish">Published</MenuItem>
                    <MenuItem value="draft">Draft</MenuItem>
                </Select>
            </TableCell>
            <TableCell>
                <Typography
                    onClick={() => {
                        if (onMembersClick) {
                            onMembersClick(campaign);
                        }
                    }}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    {campaign.members}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>{campaign.media}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{formatDate(campaign.created)}</Typography>
            </TableCell>
        </TableRow>
    );
}
