import { useCallback } from 'react';
import useApi, { Method } from 'src/hooks/useApi';
import { QueryResult } from './useKeenAnalytics';
import { Metric } from 'src/types/Analytics';

export interface Totals {
    totalRecent: number;
    totalUnique: number;
    totalOlder: number;
    totalOlderUnique: number;
    percentTotal: number;
    percentUnique: number;
}

export interface TAnalytics {
    fetch: <T>(params: {
        campaignID: number;
        metric: Metric;
        timeframe?: number;
        interval?: string;
        start?: string;
        end?: string;
        aggregated?: boolean;
        totals?: boolean;
    }) => Promise<QueryResult<T>[]>;
    fetchTotals: (params: {
        campaignID: number;
        metric: Metric;
        timeframe?: number;
        interval?: string;
        start?: string;
        end?: string;
        aggregated?: boolean;
        totals?: boolean;
    }) => Promise<Totals>;
}

enum Stat {
    Views = 'viewStats',
    Scans = 'scanStats',
}

const irdbStatFromMetric = (metric: Metric): string => {
    switch (metric) {
        case Metric.Views:
            return Stat.Views;
        case Metric.Scans:
            return Stat.Scans;
        default:
            throw new Error('Invalid metric');
    }
};

const useAnalytics = (): TAnalytics => {
    const { request } = useApi();

    const fetch = useCallback(
        async <T>({
            campaignID,
            timeframe,
            interval,
            start,
            end,
            aggregated = false,
            totals = false,
            metric,
        }: {
            campaignID: number;
            timeframe?: number;
            interval?: string;
            start?: string;
            end?: string;
            aggregated?: boolean;
            totals?: boolean;
            metric: Metric;
        }): Promise<QueryResult<T>[]> => {
            const stat = irdbStatFromMetric(metric);
            const response = await request({
                method: Method.GET,
                path: '/Statistics/fetch',
                data: {
                    campaignid: campaignID,
                    stat,
                    timeframe,
                    interval,
                    start: timeframe ? undefined : start,
                    end:
                        timeframe ? undefined
                        : end ? end
                        : start,
                    aggregated,
                    totals,
                },
            });

            switch (stat) {
                case Stat.Views:
                    return response.data.Results.viewStats;
                case Stat.Scans:
                    return response.data.Results.scanStats;
                default:
                    throw new Error('Invalid metric');
            }
        },
        [request],
    );

    const fetchTotals = useCallback(
        async <T>({
            campaignID,
            timeframe,
            interval,
            start,
            end,
            aggregated = false,
            totals = false,
            metric,
        }: {
            campaignID: number;
            timeframe?: number;
            interval?: string;
            start?: string;
            end?: string;
            aggregated?: boolean;
            totals?: boolean;
            metric: Metric;
        }): Promise<Totals> => {
            const response = await request({
                method: Method.GET,
                path: '/Statistics/fetch',
                data: {
                    campaignid: campaignID,
                    stat: irdbStatFromMetric(metric),
                    timeframe,
                    interval,
                    start: timeframe ? undefined : start,
                    end:
                        timeframe ? undefined
                        : end ? end
                        : start,
                    aggregated,
                    totals,
                },
            });
            return response.data.Results;
        },
        [request],
    );

    return {
        fetch,
        fetchTotals,
    };
};

export default useAnalytics;
