import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Color } from '../../Color';
import { AccountPrivacyForm, AccountPrivacyInfo } from '../shared/Profile/AccountPrivacy';

export interface AccountPrivacyProps {
    onClose: () => void;
}

const AccountPrivacy = ({ onClose }: AccountPrivacyProps) => {
    const { darkMode } = useContext(ThemeContext)!;
    return (
        <Container
            sx={{
                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderLightMode,
                borderRadius: '0 0 4 4',
                py: 1,
                flexGrow: 1,
            }}
        >
            <Stack textAlign="center" direction="row" position="relative" justifyContent="center">
                <Button
                    onClick={onClose}
                    sx={{ textTransform: 'none', px: 1, position: 'absolute', left: 0, top: 0, bottom: 0 }}
                    variant="irdbText"
                >
                    Close
                </Button>
                <Typography variant="webHeadlineSmall" textAlign="center" lineHeight={2}>
                    Account Privacy
                </Typography>
            </Stack>
            <Stack rowGap={4} mt={4}>
                <AccountPrivacyInfo />
                <AccountPrivacyForm />
            </Stack>
        </Container>
    );
};
export default AccountPrivacy;
