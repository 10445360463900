import { ComponentType, createContext, ReactNode, useRef } from 'react';
import { StoreApi } from 'zustand';
import { createVanillaStoreSelectors, StoreHookWithSelectors } from '../../util/zustand';
import { createProfileInfoStore, TAccount } from '../../stores/ProfileInfoStore';

const ProfileInfoContext = createContext({} as StoreHookWithSelectors<StoreApi<TAccount>>);

export const ProfileInfoProvider = ({ children }: { children: ReactNode }) => {
    const store = useRef(createVanillaStoreSelectors(createProfileInfoStore()));
    return <ProfileInfoContext.Provider value={store.current}>{children}</ProfileInfoContext.Provider>;
};

export const WithProfileInfoProvider = <T extends object>(Component: ComponentType<T>) => {
    function Render(props: T) {
        return (
            <ProfileInfoProvider>
                <Component {...props} />
            </ProfileInfoProvider>
        );
    }
    Render.displayName = `WithProfileInfoProvider(${Component.displayName || Component.name})`;
    return Render;
};

export default ProfileInfoContext;
