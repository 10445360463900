import { useProfileActions, useProfileInfo } from '../../../stores/useProfileState';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { imageAccept } from '../../../util/reactDropzone';
import { Box, BoxProps, Button } from '@mui/material';
import UserAvatar from '../../general/UserAvatar';
import { nullUndefinedOrEmpty } from '../../../util/string';

export interface UserAvatarControlProps extends BoxProps {}

const UserAvatarForm = (props: UserAvatarControlProps) => {
    const useSelectors = useProfileInfo().use;
    const profileUrl = useSelectors.profileUrl();
    const userName = useSelectors.userName();
    const { handleAddAvatar, handleRemoveAvatar } = useProfileActions();
    const onDrop = useCallback(
        (files: File[]) => {
            handleAddAvatar(files[0]);
        },
        [handleAddAvatar],
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: imageAccept });
    return (
        <Box my={1} lineHeight="3.5rem" {...props}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    gap: '.75rem',
                }}
            >
                <Box {...getRootProps()}>
                    <input {...getInputProps()} />
                    <UserAvatar
                        profileUrl={profileUrl}
                        userName={userName}
                        sx={{ height: '4rem', width: '4rem', cursor: 'pointer' }}
                    />
                </Box>
                <Box {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button variant="editProfileImageUpload">UPLOAD</Button>
                </Box>
                <Button
                    variant="editProfileImageUpload"
                    onClick={handleRemoveAvatar}
                    disabled={nullUndefinedOrEmpty(profileUrl)}
                >
                    REMOVE
                </Button>
            </Box>
        </Box>
    );
};

export default UserAvatarForm;
