import { SyntheticEvent, useContext } from 'react';
import { Paper, Stack, Tab, Tabs } from '@mui/material';
import PageHeader from './PageHeader';
import useLinkableTabs from '../../../hooks/useLinkableTabs';
import { AccountBottom, AccountTop } from './Settings/Account';
import { Notification } from './Settings/Notification';
import { IrcodePlan } from './Settings/ircodePlan';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';
import usePageTitle from 'src/hooks/usePageTitle';
import { WithProfileInfoProvider } from '../../../contexts/specialized/ProfileInfoContext';

enum TabOption {
    Account = 'Account',
    AdditionalUsers = 'Additional Users',
    Billing = 'Billing',
    IRCODEPlan = 'IRCODE Plan',
    Notifications = 'Notifications',
}

const tabs = [
    TabOption.Account,
    // TabOption.Billing,
    // TabOption.Notifications,
    // TabOption.IRCODEPlan,
    // TabOption.AdditionalUsers,
];

function Settings() {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const [tab, setTab] = useLinkableTabs(tabs);
    const currentTab = tabs[tab];

    const handleChangeTab = (tabName: TabOption) => {
        const tabIndex = tabs.indexOf(tabName);
        if (tabIndex !== -1) {
            setTab(tabIndex);
        }
    };

    usePageTitle('Settings');

    return (
        <Stack
            direction="column"
            spacing={4}
            sx={{
                p: 4,
                flexGrow: 1,
            }}
        >
            <PageHeader title="Settings" />
            <Paper
                sx={{
                    // display: { xs: 'none', sm: 'block' },
                    // p: 1,
                    p: 4,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White, // Color.LightLavenderLightMode,
                }}
                elevation={0}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    // sx={{
                    //     flexGrow: 1,
                    // }}
                >
                    <Tabs
                        value={tab}
                        onChange={(event: SyntheticEvent, newValue: number) => {
                            setTab(newValue);
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={
                            {
                                // display: 'none',
                                // flexShrink: 0,
                            }
                        }
                    >
                        {tabs.map(tab => (
                            <Tab key={tab} label={tab} />
                        ))}
                    </Tabs>

                    {currentTab === TabOption.Account && <AccountTop />}

                    {currentTab === TabOption.Billing && <>Billing</>}

                    {currentTab === TabOption.Notifications && (
                        <Notification onChangeTab={() => handleChangeTab(TabOption.Account)} />
                    )}

                    {currentTab === TabOption.IRCODEPlan && (
                        <IrcodePlan onChangeTab={() => handleChangeTab(TabOption.Billing)} />
                    )}

                    {currentTab === TabOption.AdditionalUsers && <>Additional Users</>}
                </Stack>
            </Paper>

            {currentTab === TabOption.Account && <AccountBottom />}

            {currentTab === TabOption.Billing && <>Billing Bottom</>}

            {currentTab === TabOption.Notifications && <></>}

            {currentTab === TabOption.IRCODEPlan && <></>}

            {currentTab === TabOption.AdditionalUsers && <>AdditionalUsers Bottom</>}
        </Stack>
    );
}

export default WithProfileInfoProvider(Settings);
