import { Box, Typography, Stack } from '@mui/material';
import { useContext, useState, useCallback, useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { randomColor } from 'src/util/color';
import { Color } from 'src/Color';
import useAnalytics, { Event, Parameter, Type } from 'src/hooks/useKeenAnalytics';
import { camelCaseToWords } from 'src/util/string';
import ThemeContext, { TTheme } from '../../../../../contexts/ThemeContext';
import { Source, Timeframe } from 'src/types/Analytics';

interface Props {
    source: Source;
    campaignID: number;
    timeframe?: Timeframe;
}

export default function CategoriesChart({ campaignID, timeframe }: Props) {
    interface Category {
        id: string;
        label: string;
        value: number;
        color: string;
    }

    const { query } = useAnalytics();

    const [categories, setCategories] = useState<Category[]>([]);

    const { darkMode } = useContext(ThemeContext) as TTheme;

    const fetchAnalytics = useCallback(() => {
        console.log('fetchAnalytics', campaignID, timeframe);
        return new Promise<Category[]>((resolve, reject) => {
            query(
                // Type.CountUnique,
                Type.Count,
                {
                    event_collection: Event.ImageViewed,
                    timeframe,
                    filters: [
                        {
                            property_name: Parameter.CampaignID,
                            operator: 'eq',
                            property_value: campaignID,
                        },
                    ],
                    group_by: ['category'],
                },
            )
                .then(results => {
                    resolve(
                        results
                            .map((result: any, index: number) => {
                                result.category = result.category || 'Unknown';
                                return {
                                    id: camelCaseToWords(result.category),
                                    label: camelCaseToWords(result.category),
                                    value: result.result,
                                    color: randomColor(result.category),
                                };
                            })
                            .sort((a: Category, b: Category) => b.value - a.value),
                    );
                })
                .catch((error: any) => {
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, timeframe, query]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        fetchAnalytics()
            .then((categories: Category[]) => {
                setCategories(categories);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [campaignID, fetchAnalytics]);

    return (
        <Stack direction="column">
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                }}
            >
                Categories
            </Typography>
            <Box
                style={{
                    height: 200,
                    minHeight: 200,
                    maxHeight: 250,
                    // width: '50%'
                }}
            >
                <ResponsivePie
                    // https://nivo.rocks/pie/
                    data={categories}
                    // margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]],
                    }}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    // arcLinkLabelsSkipAngle={10}
                    // arcLinkLabelsTextColor="#fff"
                    // arcLinkLabelsThickness={2}
                    // arcLinkLabelsColor={{ from: 'color' }}
                    // arcLabelsSkipAngle={10}
                    // arcLabelsTextColor={{
                    //     from: 'color',
                    //     modifiers: [
                    //         [
                    //             'darker',
                    //             2
                    //         ]
                    //     ]
                    // }}

                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true,
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10,
                        },
                    ]}
                    // colors={line => {
                    //     // console.log('line', line);
                    //     return {
                    //         fill: line.color
                    //     };
                    // }}

                    colors={categories.map((category: Category) => category.color)}
                    theme={{
                        tooltip: {
                            container: {
                                background: !darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                border: '1px solid',
                                borderColor: darkMode ? 'rgba(235, 235, 245, 0.16)' : '#eee',
                                padding: '.5em',
                                fontSize: '.8rem',
                                opacity: 0.9,
                            },
                        },
                    }}

                    // fill={[
                    //     // colors={line => {
                    //     //     // console.log('line', line);
                    //     //     return line.color;
                    //     // }}

                    //     {
                    //         match: {
                    //             id: 'ruby'
                    //         },
                    //         id: 'dots'
                    //     },
                    //     {
                    //         match: {
                    //             id: 'c'
                    //         },
                    //         id: 'dots'
                    //     },
                    //     {
                    //         match: {
                    //             id: 'go'
                    //         },
                    //         id: 'dots'
                    //     },
                    //     {
                    //         match: {
                    //             id: 'python'
                    //         },
                    //         id: 'dots'
                    //     },
                    //     {
                    //         match: {
                    //             id: 'scala'
                    //         },
                    //         id: 'lines'
                    //     },
                    //     {
                    //         match: {
                    //             id: 'lisp'
                    //         },
                    //         id: 'lines'
                    //     },
                    //     {
                    //         match: {
                    //             id: 'elixir'
                    //         },
                    //         id: 'lines'
                    //     },
                    //     {
                    //         match: {
                    //             id: 'javascript'
                    //         },
                    //         id: 'lines'
                    //     }
                    // ]}

                    // legends={[
                    //     {
                    //         anchor: 'bottom',
                    //         direction: 'row',
                    //         justify: false,
                    //         translateX: 0,
                    //         translateY: 56,
                    //         itemsSpacing: 0,
                    //         itemWidth: 100,
                    //         itemHeight: 18,
                    //         itemTextColor: '#999',
                    //         itemDirection: 'left-to-right',
                    //         itemOpacity: 1,
                    //         symbolSize: 18,
                    //         symbolShape: 'circle',
                    //         effects: [
                    //             {
                    //                 on: 'hover',
                    //                 style: {
                    //                     itemTextColor: '#000'
                    //                 }
                    //             }
                    //         ]
                    //     }
                    // ]}
                />
            </Box>
            <Stack
                direction="row"
                sx={{
                    flexWrap: 'wrap',
                }}
            >
                {categories.map((category: Category, index: number) => {
                    return (
                        <Stack
                            key={category.id}
                            direction="row"
                            spacing={1}
                            sx={{
                                mb: 1,
                                alignItems: 'center',
                                width: '50%',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    flexShrink: 0,
                                    flexBasis: 10,
                                    width: 10,
                                    height: 10,
                                    // borderRadius: 4,
                                    // objectFit: 'cover',
                                    backgroundColor: category.color,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '16px',
                                    letterSpacing: '0.15em',
                                    textAlign: 'left',
                                }}
                            >
                                {category.label}
                            </Typography>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
}
