import {
    Box,
    Button,
    Checkbox,
    Chip,
    Collapse,
    FormControlLabel,
    IconButton,
    Link as MuiLink,
    Stack,
    StackProps,
    Tooltip,
} from '@mui/material';
import { forwardRef, ReactNode, useContext, useRef } from 'react';
import DragDropHandle from 'src/components/general/DragDropHandle';
import DragDropList from 'src/components/general/DragDropList';
import { reorder, replaceItem } from 'src/util/array';
import { Color } from '../../../../Color';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { createLink, ILink, isTLink, TLink } from '../../../../types/Link';
import { nullUndefinedOrEmpty } from '../../../../util/string';
import TextField from '../../../general/TextField';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { ILinkErrors } from '../../../../util/metaValidation/link';
import { validateMetaField } from '../../../../contexts/MetaContext';
import { MetaType } from '../../../../types/MetaTypes';
import useScopeMetaErrors, { useScopeMetaErrorsActions } from '../../../../hooks/metaErrors/useScopeMetaErrors';
import useEnsureIds from '../../../../hooks/useEnsureIds';
import HelperTextBox from '../../../general/HelperTextBox';

const Link = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TLink | undefined;

    if (!content) {
        return null;
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                width: '100%',
                overflowX: 'auto',
                // width: '50px',
                // overflowX: 'auto',
                // p: 2,
            }}
        >
            {(isTLink(content) ? content.links : []).map(link => {
                let url: URL | undefined;
                try {
                    if (!nullUndefinedOrEmpty(link.url) && !/^https?:\/\//i.test(link.url)) {
                        link.url = 'https://' + link.url;
                    }

                    url = new URL(link.url);
                } catch (error) {
                    // console.error('Error creating URL:', error);
                }

                if (!url) {
                    return null;
                }

                // if (url) {
                //     // url = `https://${url}`;
                //     console.log('url', JSON.stringify(url, null, 4));
                // console.log('url', url);
                // }

                return (
                    <Box key={url.href}>
                        <MuiLink href={url.href} target="_blank" rel="noreferrer noopener">
                            <Chip
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    lineHeight: '26px',
                                    letterSpacing: '0.15em',
                                    textTransform: 'uppercase',
                                    color: darkMode ? Color.White : Color.Black,
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode, //  Color.White,
                                    // "&:hover": {
                                    //     backgroundColor: Color.primary,
                                    //     color: Color.white,
                                    // },
                                }}
                                // label={url}
                                // <i class="fa-sharp fa-solid fa-link"></i>
                                label={
                                    <>
                                        {!nullUndefinedOrEmpty(link.title) ? link.title : url.hostname}
                                        <i
                                            className="fa-sharp fa-solid fa-link"
                                            style={{
                                                marginLeft: 4,
                                                color: darkMode ? Color.White : Color.Black,
                                            }}
                                        ></i>
                                    </>
                                }
                            />
                        </MuiLink>
                    </Box>
                );
            })}
        </Stack>
    );
};

interface LinkFormItemProps extends StackProps<'div'> {
    disabled?: boolean;
    darkMode?: boolean;
    link: ILink;
    onLinkChange: (link: ILink) => void;
    onLinkCopy?: (link: ILink) => void;
    handle?: ReactNode;
    onDeleteLink: () => void;
    errors?: ILinkErrors;
    onValidate?: (link: ILink, field: keyof ILink) => void;
    scanToSiteDisabled?: boolean;
}

const LinkFormItem = forwardRef<HTMLDivElement, LinkFormItemProps>(function LinkFormItem(
    {
        disabled,
        darkMode,
        link,
        handle,
        onLinkChange: onChange,
        onLinkCopy: onCopy,
        onDeleteLink,
        errors,
        onValidate,
        scanToSiteDisabled,
        ...rest
    },
    ref,
) {
    const urlDidBlur = useRef(false);
    return (
        <Stack
            ref={ref}
            spacing={1}
            {...rest}
            direction="row"
            sx={{
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                ...rest.sx,
            }}
        >
            <Box
                sx={{
                    minWidth: 4,
                    flexBasis: 4,
                    backgroundColor: darkMode ? 'rgba(1, 1, 1, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                }}
            />
            {handle}
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    flexGrow: 1,
                    p: 1,
                }}
            >
                <TextField
                    disabled={disabled}
                    label="URL"
                    value={link.url}
                    onChange={value => {
                        const newLink = { ...structuredClone(link), url: value };
                        onChange(newLink);
                        if (!urlDidBlur.current) return;
                        onValidate?.(newLink, 'url');
                    }}
                    onBlur={() => {
                        urlDidBlur.current = true;
                        onValidate?.(link, 'url');
                    }}
                    error={!!errors?.url}
                    helperText={errors?.url}
                    wrapperSx={{
                        flexGrow: 1,
                    }}
                />
                <TextField
                    disabled={disabled}
                    label="Title"
                    value={link.title}
                    onChange={value => onChange({ ...structuredClone(link), title: value })}
                    error={!!errors?.title}
                    helperText={errors?.title}
                    wrapperSx={{
                        flexGrow: 1,
                    }}
                />
                <Stack spacing={0.5}>
                    <HelperTextBox helperText="Link opens automatically when someone scans your IRCODE">
                        <Tooltip
                            disableFocusListener={!scanToSiteDisabled}
                            disableHoverListener={!scanToSiteDisabled}
                            disableTouchListener={!scanToSiteDisabled}
                            title="Each IRCODE can have one Scan-To-Site link"
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [60, -15],
                                        },
                                    },
                                ],
                            }}
                        >
                            <FormControlLabel
                                disabled={disabled || scanToSiteDisabled}
                                control={
                                    <Checkbox
                                        size="small"
                                        disabled={disabled || scanToSiteDisabled}
                                        checked={link.onScanDisplay}
                                        onChange={event => {
                                            const newValue = event.target.checked;
                                            onChange({
                                                ...structuredClone(link),
                                                onScanDisplay: newValue,
                                                ...(!newValue && { privateDetails: false }),
                                            });
                                        }}
                                    />
                                }
                                label="Scan-To-Site"
                            />
                        </Tooltip>
                    </HelperTextBox>
                    <Collapse in={link.onScanDisplay}>
                        <HelperTextBox helperText="People who scan your IRCODE will not see the IRCODE details sheet">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        disabled={disabled || !link.onScanDisplay}
                                        checked={!!link.privateDetails}
                                        onChange={event => {
                                            onChange({
                                                ...structuredClone(link),
                                                privateDetails: event.target.checked,
                                            });
                                        }}
                                    />
                                }
                                label="Private Details"
                            />
                        </HelperTextBox>
                    </Collapse>
                    <HelperTextBox
                        pb={1}
                        helperText="When scanned using the IRCODE app, this link will not open in IRCODE's built-in browser"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    disabled={disabled}
                                    checked={!!link.openInNewWindow}
                                    onChange={event => {
                                        onChange({ ...structuredClone(link), openInNewWindow: event.target.checked });
                                    }}
                                />
                            }
                            label="Open In External Browser"
                        />
                    </HelperTextBox>
                </Stack>
            </Stack>
            <Stack
                direction="column"
                sx={{
                    justifyContent: 'center',
                }}
            >
                {onCopy && (
                    <IconButton
                        disabled={disabled}
                        sx={{
                            fontSize: '1rem',
                        }}
                        onClick={() => {
                            onCopy(link);
                        }}
                    >
                        <i className="fa-regular fa-copy" />
                    </IconButton>
                )}
                <IconButton
                    disabled={disabled}
                    sx={{
                        width: '2em',
                        height: '2em',
                        alignSelf: 'center',
                    }}
                    onClick={() => onDeleteLink()}
                >
                    <i className="fa-solid fa-xmark" />
                </IconButton>
            </Stack>
        </Stack>
    );
});

const LinkForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TLink;

    const links = content?.links ?? [];
    const setLinks = (links: ILink[]) => {
        onChange?.({ links });
    };

    useEnsureIds(links, setLinks);

    const scanToSiteLink = links.find(link => link.onScanDisplay);

    const errors = useScopeMetaErrors(state => state?.[MetaType.Link]);
    const { setError, removeError } = useScopeMetaErrorsActions();

    const setLink = (index: number, value: ILink) => {
        const newLinks = [...links];
        newLinks[index] = value;
        setLinks(newLinks);
    };

    const onDeleteLink = (index: number) => {
        const newLinks = structuredClone(links);
        newLinks.splice(index, 1);
        setLinks(newLinks);

        if (!errors) return;
        if (newLinks.length === 0) {
            removeError(MetaType.Link);
            return;
        }
        const newErrors = errors.filter(error => error.id !== links[index].id);
        if (newErrors.length !== errors.length) {
            setError(MetaType.Link, newErrors);
        }
    };

    const handleValidate = (link: ILink, field: keyof ILink) => {
        const newLinks = replaceItem(
            [...links],
            () => link,
            item => item.id === link.id,
        );
        const { errors: fieldErrors } = validateMetaField(
            MetaType.Link,
            { links: newLinks },
            { filter: [{ id: link.id, fields: [field] }], mergeErrors: errors },
        );
        if (fieldErrors) {
            setError(MetaType.Link, fieldErrors);
        } else {
            removeError(MetaType.Link);
        }
    };
    const items = isTLink(content) ? content.links : [];
    const isDragDropEnabled = items.length > 1;

    return (
        <>
            {/* This works, but not for any good reason */}
            <DragDropList
                disabled={!isDragDropEnabled}
                items={items}
                listRender={(children, provided) => (
                    <Stack {...provided.droppableProps} ref={provided.innerRef} rowGap={2}>
                        {children}
                        {provided.placeholder}
                    </Stack>
                )}
                itemRender={([link, index], provided, { isDragging, isDropAnimating }) => (
                    <LinkFormItem
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        handle={isDragDropEnabled ? <DragDropHandle {...provided.dragHandleProps} sm /> : undefined}
                        disabled={isDragging || isDropAnimating}
                        darkMode={darkMode}
                        link={link}
                        onLinkChange={link => setLink(index, link)}
                        onLinkCopy={onCopy && (() => onCopy({ links: [link] }))}
                        onDeleteLink={() => onDeleteLink(index)}
                        errors={errors?.find(error => error.id === link.id)?.errors}
                        onValidate={handleValidate}
                        scanToSiteDisabled={scanToSiteLink && scanToSiteLink.id !== link.id}
                    />
                )}
                onDragEnd={result => {
                    if (!result.destination) {
                        return;
                    }

                    const newLinks = reorder(links, result.source.index, result.destination.index);
                    setLinks(newLinks);
                }}
            />
            <Button
                variant="irdbGray"
                sx={{}}
                onClick={() => {
                    const newLinks: ILink[] = [...(isTLink(content) ? content.links : links), createLink()];
                    setLinks(newLinks);
                }}
            >
                <i className="fa-solid fa-link"></i>
                Add Link
            </Button>
        </>
    );
};

export { Link, LinkForm };
