import { useEffect, useContext, useState, useRef, useCallback } from 'react';
import { Avatar, Box, CircularProgress, Divider, IconButton, Stack, SwipeableDrawer, Typography } from '@mui/material';
import { Color } from 'src/Color';
import UserContext, { UserNotification, TUser } from 'src/contexts/UserContext';
import { NotificationType } from '../admin/pages/Notifications';
import { ZIndex } from 'src/App';
import Drawer from '../general/Drawer';
import Info from '../image/Info';
import MediaContext, { TMedia } from '../../contexts/MediaContext';
import { Image } from '../../types/Image';
import { formatDateWebApp } from 'src/util/date';
import useOnScreen from 'src/hooks/useOnScreen';

interface NotificationProps {
    notification: UserNotification;
    onVisible: (notificationID: number) => void;
    onClick: (imageID: string) => void;
}

function Notification({ notification, onVisible, onClick }: NotificationProps) {
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);

    useEffect(() => {
        if (isVisible && !notification.readFlag) {
            const timer = setTimeout(() => {
                onVisible(notification.notificationID);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [isVisible, onVisible, notification.readFlag]);

    function getNotificationDetails(notification: UserNotification) {
        const notificationType = notification.notificationType;
        const ircodeTitle = notification.title ? notification.title : 'Untitled';

        let iconClass = '';
        let body = '';
        let activityUser = '';

        if (notification.ActivityUser.userID) {
            activityUser = notification.ActivityUser.userName ? notification.ActivityUser.userName : 'Someone';
        }

        switch (notificationType as NotificationType) {
            case NotificationType.Save:
                iconClass = 'fa-sharp fa-star';
                body = `added an IRCODE to their saved codes.`;
                break;
            case NotificationType.IrCodeReceived:
                iconClass = 'fa-light fa-download';
                body = `received your IRCODE.`;
                break;
            case NotificationType.IrCodeTransferred:
                iconClass = 'fa-light fa-exchange';
                body = `You transferred IRCODE ${ircodeTitle}.`;
                break;
            case NotificationType.IRCodeRemoved:
                iconClass = 'fa-light fa-trash';
                body = `removed an IRCODE.`;
                break;
            case NotificationType.Payment:
                iconClass = 'fa-light fa-dollar-sign';
                body = `made a payment. Your account is now up to date.`;
                break;
            case NotificationType.Query:
                iconClass = 'fa-light fa-camera-viewfinder';
                body = `queried an IRCODE.`;
                break;
            case NotificationType.Search:
                iconClass = 'fa-light fa-magnifying-glass';
                body = `searched for an IRCODE and found yours. Thank you for registering your code.`;
                break;
            case NotificationType.Share:
                iconClass = 'fa-light fa-square-arrow-up';
                body = `shared an IRCODE.`;
                break;
            case NotificationType.View:
                iconClass = 'fa-light fa-eye';
                body = `viewed an IRCODE.`;
                break;
            case NotificationType.Similar:
                iconClass = 'fa-light fa-photo-film';
                body = `found a similar IRCODE.`;
                break;
            case NotificationType.Sponsored:
                iconClass = 'fa-light fa-wand-sparkles';
                body = `sponsored an IRCODE.`;
                break;
            case NotificationType.TransferToInProcess:
                iconClass = 'fa-light fa-cloud-arrow-up';
                body = `Transfer ${ircodeTitle} in process.`;
                break;
            case NotificationType.TransferFromInProcess:
                iconClass = 'fa-light fa-cloud-arrow-down';
                body = `Transfer ${ircodeTitle} in process.`;
                break;
            case NotificationType.TransferAccepted:
                iconClass = 'fa-light fa-cloud-check';
                body = `accepted ${ircodeTitle} transfer.`;
                break;
            case NotificationType.TransferCancelled:
                iconClass = 'fa-light fa-cloud-xmark';
                body = `cancelled ${ircodeTitle} transfer.`;
                break;
            default:
                iconClass = '';
                body = 'Error retrieving notification.';
                break;
        }
        return { iconClass, body, activityUser };
    }

    const { iconClass, body, activityUser } = getNotificationDetails(notification);

    return (
        <Box ref={ref} onClick={() => onClick(notification.imageID)}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        alignItems: 'flex-start',
                        flexGrow: 1,
                    }}
                >
                    <i
                        className={iconClass}
                        style={{
                            fontSize: '1rem',
                            lineHeight: '24px',
                            color: Color.PrimaryLavender,
                        }}
                    />
                    <Stack direction="column" sx={{ alignItems: 'flex-start' }}>
                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                            {activityUser && (
                                <Avatar
                                    src={notification.ActivityUser.profileUrl}
                                    alt={notification.ActivityUser.userName}
                                    sx={{ width: 24, height: 24 }}
                                />
                            )}
                            <Typography
                                variant="webFont8"
                                sx={{
                                    lineHeight: activityUser ? '30px' : '24px',
                                    letterSpacing: 0,
                                    opacity: '70%',
                                }}
                            >
                                {formatDateWebApp(notification.created)}
                            </Typography>
                            {!notification.readFlag && (
                                <Typography variant="webFont8" sx={{ lineHeight: 0 }} color="error">
                                    new
                                </Typography>
                            )}
                        </Stack>
                        <Typography
                            variant="metaDataValue"
                            sx={{
                                fontWeight: 400,
                                lineHeight: '1.4em',
                            }}
                        >
                            {activityUser && <span style={{ fontWeight: 'bold' }}>{activityUser}&nbsp;</span>}
                            {notification.displayLine || body}
                        </Typography>
                    </Stack>
                </Stack>

                <Box
                    component="img"
                    sx={{
                        flexShrink: 0,
                        width: { xs: 64 },
                        height: { xs: 64 },
                        objectFit: 'cover',
                        borderRadius: '4px',
                    }}
                    src={notification.imageUrl}
                    alt=""
                    loading="lazy"
                />
            </Stack>
            <Divider sx={{ pt: 2 }} />
        </Box>
    );
}

interface Props {
    notifications: UserNotification[];
    emptyMessage?: string;
    onClose: () => void;
    onUnmount?: () => void;
    showLoading: boolean;
}

export default function NotificationsList({ notifications, emptyMessage, onClose, onUnmount, showLoading }: Props) {
    const { notificationRead } = useContext(UserContext) as TUser;
    const { load } = useContext(MediaContext) as TMedia;
    const [selectedImage, setSelectedImage] = useState<Image>();
    const [localNotifications, setLocalNotifications] = useState<UserNotification[]>(notifications);

    useEffect(() => {
        setLocalNotifications(notifications);
    }, [notifications]);

    const handleNotificationClick = async (imageID: string) => {
        try {
            const image = await load(imageID);
            setSelectedImage(image);
        } catch (error) {
            console.error('Failed to load image', error);
        }
    };

    const handleDrawerClose = () => {
        setSelectedImage(undefined);
    };

    const handleNotificationVisible = useCallback(
        async (notificationID: number) => {
            try {
                await notificationRead(true, notificationID);
                setLocalNotifications(prevNotifications =>
                    prevNotifications.map(notification =>
                        notification.notificationID === notificationID ?
                            { ...notification, readFlag: true }
                        :   notification,
                    ),
                );
            } catch (error) {
                console.error('Failed to mark notification as read', error);
            }
        },
        [notificationRead],
    );

    useEffect(() => {
        return () => {
            onUnmount?.();
        };
    }, []);

    return (
        <Stack direction="column" spacing={2} sx={{ flexGrow: 1, p: 2 }}>
            <Stack
                direction="row"
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        flexGrow: 1,
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="webHeadlineSmall"
                        sx={{
                            textAlign: 'center',
                            lineHeight: 2,
                        }}
                    >
                        Notifications
                    </Typography>
                </Box>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: '.5em',
                    }}
                >
                    <i className="fa-sharp fa-light fa-xmark" />
                </IconButton>
            </Stack>

            {showLoading ?
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            : (
                notifications.filter(notification => notification.imageUrl && notification.imageUrl.trim() !== '')
                    .length === 0 && emptyMessage
            ) ?
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            :   localNotifications
                    .filter(notification => notification.imageUrl && notification.imageUrl.trim() !== '')
                    .map(notification => (
                        <Notification
                            key={notification.notificationID}
                            notification={notification}
                            onVisible={handleNotificationVisible}
                            onClick={handleNotificationClick}
                        />
                    ))
            }

            <SwipeableDrawer
                anchor="bottom"
                open={selectedImage !== undefined}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                }}
                onClose={handleDrawerClose}
                onOpen={() => {}}
                style={{
                    zIndex: ZIndex.ImageList,
                }}
            >
                <Drawer>{selectedImage && <Info image={selectedImage} onClose={handleDrawerClose} />}</Drawer>
            </SwipeableDrawer>
        </Stack>
    );
}
