import { ImageOperation } from "./MediaContext";

export type ProgressHandler = (progress: ImageOperation<any>) => void;

export function getProgressHandler(
    onProgress: (progress: ImageOperation<any>) => void,
    abortController: AbortController,
): ProgressHandler;
export function getProgressHandler(
    onProgress: ProgressHandler | undefined,
    abortController: AbortController,
): ProgressHandler | undefined;
export function getProgressHandler(onProgress: ProgressHandler | undefined, abortController: AbortController): unknown {
    if (!onProgress) {
        return undefined;
    }
    return (progress: ImageOperation<any>) => {
        if (abortController.signal.aborted) {
            return;
        }
        onProgress(progress);
    };
}
