import { useState, useContext } from 'react';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { Color } from '../../Color';
import { TwoFAType } from 'src/contexts/UserContext';

interface RemoveUserProps {
    usersToRemove: UserSearchResults[];
    onClose: () => void;
    setRemoveDialog: (open: boolean) => void;
}

export default function RemoveUser({ usersToRemove, onClose, setRemoveDialog }: RemoveUserProps) {
    const { requestAdminTwoFactorAuth, removeUser } = useContext(AdminContext) as TAdmin;
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const { notify } = useContext(FeedbackContext) as TFeedback;

    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setRemoveDialog(false);
        onClose();
    };

    const handleConfirmRemove = async () => {
        try {
            const twoFACode = await requestAdminTwoFactorAuth(
                'Confirm User Deletion',
                'Your phone number here',
                'Confirm',
                TwoFAType.AdminFunction,
            );
            await Promise.allSettled(usersToRemove.map(user => removeUser(user?.userID, twoFACode)));
            notify('Account Locked', 'User(s) account locked and marked for deletion');
            handleCloseConfirmDialog();
        } catch (error) {
            console.error('Error removing user:', error);
            alert('Failed to remove user.');
        }
    };

    return (
        <>
            <Box sx={{ p: 2, border: '1px solid', borderColor: Color.LightLavenderDarkMode, borderRadius: '8px' }}>
                <Stack direction="column" spacing={2} p={2}>
                    <Typography variant="h6" align="center">
                        Would you like to remove these accounts?
                    </Typography>
                    {usersToRemove.map((user, index) => (
                        <Box key={index} sx={{ mb: 2, borderBottom: '1px solid', pb: 1 }}>
                            <Avatar src={user.profileUrl} />
                            <Typography>Username: {user.userName}</Typography>
                            <Typography>Full Name: {user.fullName}</Typography>
                            <Typography>Email: {user.email}</Typography>
                            <Typography>Phone: {user.phone}</Typography>
                        </Box>
                    ))}
                    <Button onClick={handleOpenConfirmDialog} variant="webTextButton" sx={{ color: 'red' }}>
                        Remove User
                    </Button>
                    <Button onClick={handleCloseConfirmDialog} variant="webTextButton" color="primary">
                        Cancel
                    </Button>
                </Stack>
            </Box>

            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <DialogTitle>Confirm User Removal</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to remove this user?</Typography>
                    <Typography>After confirming 2FA code:</Typography>
                    <Typography>- Selected user is marked to be removed in 30 days</Typography>
                    <Typography>- Users IRCODES marked to be removed within 48 hours</Typography>
                    <Typography>- User account is locked</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} variant="webTextButton">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmRemove} variant="webTextButton" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
