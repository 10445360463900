import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { createStoreSelectors } from '../../util/zustand';
import { create } from 'zustand';
import useExpiringAction from '../useExpiringAction';

export interface StatusMessagesStore {
    successMessage: string | undefined;
    errorMessage: string | undefined;
    setSuccessMessage: (message?: string) => void;
    setErrorMessage: (message?: string) => void;
}

const useStatusMessagesStore = createStoreSelectors(
    create<StatusMessagesStore>()((set, get) => ({
        successMessage: undefined,
        errorMessage: undefined,
        setSuccessMessage: (message?: string) => set({ successMessage: message }),
        setErrorMessage: (message?: string) => set({ errorMessage: message }),
    })),
);

export const StatusMessagesSnackbar = () => {
    const successMessage = useStatusMessagesStore.use.successMessage?.();
    const errorMessage = useStatusMessagesStore.use.errorMessage?.();
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={successMessage !== undefined}
                autoHideDuration={5000}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={errorMessage !== undefined}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

const useStatusMessages = () => {
    const setSuccessMessage = useStatusMessagesStore.use.setSuccessMessage();
    const setErrorMessage = useStatusMessagesStore.use.setErrorMessage();
    const setSuccessMessageExpiring = useExpiringAction(5000, setSuccessMessage, () => setSuccessMessage(undefined));
    const setErrorMessageExpiring = useExpiringAction(5000, setErrorMessage, () => setErrorMessage(undefined));

    return { setSuccessMessage: setSuccessMessageExpiring, setErrorMessage: setErrorMessageExpiring };
};

export default useStatusMessages;
