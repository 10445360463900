import { Dispatch, SetStateAction, useMemo, useState } from 'react';

export interface UseBooleanState<T extends boolean | undefined> {
    setState: Dispatch<SetStateAction<T>>;
    setTrue: () => void;
    setFalse: () => void;
    toggle: () => void;
}

function useBooleanState(initialState: boolean): readonly [boolean, UseBooleanState<boolean>];
function useBooleanState(initialState?: boolean): readonly [boolean | undefined, UseBooleanState<boolean | undefined>];
function useBooleanState(initialState?: boolean): unknown {
    const [state, setState] = useState(initialState);
    const methods: UseBooleanState<boolean | undefined> = useMemo(
        () => ({
            setState,
            setTrue: () => setState(true),
            setFalse: () => setState(false),
            toggle: () => setState(prevState => !prevState),
        }),
        [setState],
    );

    return [state, methods];
}

export default useBooleanState;
