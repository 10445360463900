import { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Stack, Box, Button, Avatar, Paper, Typography, Tabs, Tab, Dialog } from '@mui/material';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import { nullUndefinedOrEmpty } from '../../../util/string';
import UserContext, { TUser } from '../../../contexts/UserContext';
import { Quality, shrink } from '../../../util/image';
import FirebaseContext, { TFirebase } from '../../../contexts/FirebaseContext';
import TextField from '../../general/TextField';
import PageHeader from './PageHeader';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';
import useLinkableTabs from '../../../hooks/useLinkableTabs';
import { useNavigate, useParams } from 'react-router-dom';
import EnterpriseContext, {
    Campaign,
    DefaultCampaignImageUrl,
    Permission,
    TEnterprise,
} from '../../../contexts/EnterpriseContext';
import BulkUploader from '../uploader/BulkUploader';
import MediaContext, { PagedResults, TMedia } from '../../../contexts/MediaContext';
import OwnedIrcodes from './OwnedIrcodes';
import { IconButton } from 'src/components/general/IconButton';
import { AnalyticsTop } from './Enterprise/Analytics';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import { Select } from 'src/components/general/Select';
import { ImageSortByField, ImageSortOrderField, Image } from 'src/types/Image';
import usePageTitle from 'src/hooks/usePageTitle';
import FileDropArea from 'src/components/general/FileDropArea';
import { imageAccept } from 'src/util/reactDropzone';
import { Products } from './Enterprise/Products';

const permissions = [
    {
        value: Permission.None,
        label: 'None',
    },
    {
        value: Permission.User,
        label: 'User',
    },
    {
        value: Permission.SuperAdmin,
        label: 'Super-Admin',
    },
];

enum TabOption {
    Analytics = 'Analytics',
    Ircodes = 'IRCODES',
    Products = 'Products',
}

const tabs = [TabOption.Ircodes, TabOption.Analytics, TabOption.Products];

export function CampaignEdit() {
    const navigate = useNavigate();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { id } = useParams();

    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { confirm, notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { upload: firebaseUpload } = useContext(FirebaseContext) as TFirebase;
    const { user } = useContext(UserContext) as TUser;
    const { remove } = useContext(MediaContext) as TMedia;
    const {
        fetchImages: fetchCampaignImages,
        create,
        update,
        load: fetchCampaign,
        addImage,
        removeImage: removeImageFromCampaign,
        removeCampaignImages,
        deleteCampaign,
        users: fetchUsers,
        invite,
        updatePermission,
        deletePermission,
    } = useContext(EnterpriseContext) as TEnterprise;

    const onDrop = useCallback((files: File[]) => {
        setFile(files[0]);
    }, []);

    const savedCampaign = useRef<Campaign>();
    const [campaignName, setCampaignName] = useState<string>();
    const [campaignDescription, setCampaignDescription] = useState<string>();
    const [campaignImageUrl, setCampaignImageUrl] = useState<string>();
    const [campaignCreated, setCampaignCreated] = useState<number>();

    const [images, setImages] = useState<Image[]>([]);
    const [users, setUsers] = useState<PagedResults<any[]>>();

    const [file, setFile] = useState<File>();

    // const handleRemoveAvatar = () => {
    //     setFile(undefined);
    //     setCampaignImageUrl(undefined);
    // };

    const [isProcessing, setIsProcessing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    // TODO: Allow ability to not have default tab
    const [tab, setTab] = useLinkableTabs(tabs);
    const currentTab = tabs[tab];

    const [showManageMembers, setShowManageMembers] = useState(false);

    const [invitationEmail, setInvitationEmail] = useState<string>('');
    const [invitationPermission, setInvitationPermission] = useState<Permission>(permissions[0].value);

    const isNewCampaign = id === 'new';
    const isEditingEnabled = isEditing || isNewCampaign;

    useEffect(() => {
        if (file) {
            setCampaignImageUrl(URL.createObjectURL(file));
        } else {
            setCampaignImageUrl(savedCampaign.current?.campaignImageUrl);
        }
    }, [file]);

    useEffect(() => {
        if (isProcessing) {
            setIsEditing(false);
        }
    }, [isProcessing]);

    const fetchCampaignCallback = useCallback(
        (id: number) => {
            fetchCampaign(id)
                .then(campaign => {
                    if (campaign) {
                        savedCampaign.current = campaign;
                        setCampaignName(campaign.campaignName);
                        setCampaignDescription(campaign.campaignDescription);
                        setCampaignImageUrl(campaign.campaignImageUrl);
                        setCampaignCreated(campaign.created);
                    }
                })
                .catch((error: Error) => {
                    if (error.message === 'not the owner') {
                        navigate('/enterprise');
                    }
                })
                .finally(() => {
                    setShowLoading(false);
                });
        },
        // fetchCampaign(parsedId)
        // .then((campaign) => {
        //     if (campaign) {
        //         setCampaignName(campaign.campaignName);
        //         setCampaignDescription(campaign.campaignDescription);
        //         setCampaignImageUrl(campaign.campaignImageUrl);
        //     }
        // })
        // .catch(error => {
        //     console.error(error);
        // })
        // .finally(() => {
        //     setShowLoading(false);
        // });

        // (id: number): Promise<Campaign> => {
        //     return new Promise((resolve, reject) => {
        //         fetchCampaign(id)
        //             .then((campaign) => {
        //                 resolve(campaign);
        //             })
        //             .catch((error) => {
        //                 reject(error);
        //             });
        //     });
        // },
        [fetchCampaign, setShowLoading],
    );

    const fetchCampaignImagesCallback = useCallback(
        (
            page: number,
            limit: number,
            sortBy: ImageSortByField,
            sortOrder: ImageSortOrderField,
        ): Promise<PagedResults<Image[]>> => {
            return new Promise((resolve, reject) => {
                if (user?.userID === undefined) {
                    reject('User not found');
                }

                // TODO: Make this accept page
                fetchCampaignImages(parseInt(id!), page, limit, sortBy, sortOrder)
                    .then(results => {
                        resolve(results);
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {});
            });
        },
        [id, user, fetchCampaignImages],
    );

    // const fetchCampaignUsers = (id: number) => {
    //     fetchUsers(id)
    //         .then((results) => {
    //             setUsers(results);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }

    const fetchCampaignUsersCallback = useCallback(
        (id: number) => {
            fetchUsers(id)
                .then(results => {
                    setUsers(results);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        [fetchUsers],
    );

    useEffect(() => {
        if (!showManageMembers) {
            setInvitationEmail('');
            setInvitationPermission(permissions[0].value);
        }
    }, [showManageMembers]);

    useEffect(() => {
        console.log('useEffect user', user);

        if (!isNewCampaign) {
            setShowLoading(true);
        }

        if (!id || !user) {
            return;
        }

        if (!isNewCampaign && id) {
            setShowLoading(true);
            const parsedId = parseInt(id);

            fetchCampaignCallback(parsedId);
            fetchCampaignUsersCallback(parsedId);
        }
    }, [id, user, fetchCampaignImagesCallback, fetchCampaignUsersCallback, setShowLoading, fetchCampaignCallback]);

    const getDownloadUrl = async (file: File) => {
        const shrunkenFile = await shrink(file, Quality.High);
        const downloadUrl = await firebaseUpload(shrunkenFile.file, 'campaigns', progress => {});
        return downloadUrl;
    };

    const save = async () => {
        if (nullUndefinedOrEmpty(campaignName)) {
            notify('Missing fields', 'Must fill out name field');
            return;
        }

        setShowLoading(true);

        const campaign = {
            campaignName: campaignName!,
            campaignDescription: campaignDescription!,
            campaignImageUrl,
        };

        if (file) {
            const downloadUrl = await getDownloadUrl(file);
            campaign.campaignImageUrl = downloadUrl;
        }

        try {
            if (isNewCampaign) {
                const campaignID = await create(campaign);
                navigate(`/enterprise/campaigns/${campaignID}`);
            } else {
                const newCampaign = {
                    campaignID: parseInt(id!),
                    ...campaign,
                };
                await update(newCampaign);
                savedCampaign.current = newCampaign;
            }

            setIsEditing(false);
            setFile(undefined);
        } catch (error) {
            console.error(error);
            notify('Error', String.fromError(error));
        } finally {
            setShowLoading(false);
        }
    };

    const handleDeleteCampaign = async () => {
        try {
            if (
                await confirm({
                    title: 'Delete Campaign',
                    message: 'Are you sure you want to delete this campaign?',
                    yes: 'Delete',
                    no: 'Cancel',
                    destructive: true,
                })
            ) {
                setShowLoading(true);
                if (
                    savedCampaign.current?.media &&
                    (await confirm({
                        title: 'Delete Campaign IRCODES?',
                        message: 'Do you want to also delete all IRCODES you owned that were in this campaign?',
                        yes: 'Yes, Delete IRCODES',
                        no: 'No, Keep IRCODES',
                        destructive: true,
                    }))
                ) {
                    await removeCampaignImages(campaignID);
                }
                await deleteCampaign(campaignID);
                navigate('/enterprise/campaigns');
            }
        } catch (error) {
            console.error(error);
            notify('Error', String.fromError(error));
        } finally {
            setShowLoading(false);
        }
    };

    const campaignID = parseInt(id!);

    const didCampaignChange = () =>
        !!savedCampaign &&
        (campaignName !== savedCampaign.current?.campaignName ||
            campaignDescription !== savedCampaign.current?.campaignDescription ||
            campaignImageUrl !== savedCampaign.current?.campaignImageUrl);

    const editButtonProps =
        isEditing ?
            {
                onClick: async () => {
                    const didChange = didCampaignChange();
                    if (didChange) {
                        if (
                            !(await confirm({
                                title: 'Cancel',
                                message: 'Unsaved changes will be lost. Are you sure you want to cancel?',
                            }))
                        ) {
                            return;
                        }
                    }
                    setIsEditing(false);
                    setCampaignName(savedCampaign.current?.campaignName);
                    setCampaignDescription(savedCampaign.current?.campaignDescription);
                    setCampaignImageUrl(savedCampaign.current?.campaignImageUrl);
                },
                iconClassName: 'fa-xmark',
                label: 'Cancel',
            }
        :   {
                onClick: async () => {
                    // if (await confirm({
                    //     title: 'Confirm Replace',
                    //     message: 'Replace all metadata across selected IRCODES?',
                    // })) {
                    //     onReplace(image);
                    // }
                    setIsEditing(true);
                },
                iconClassName: 'fa-copy',
                label: 'Edit',
            };

    // console.log('profileUrl', profileUrl);
    // console.

    usePageTitle(campaignName ?? 'Campaigns');

    return (
        <>
            <Stack
                direction="column"
                spacing={4}
                sx={{
                    p: 4,
                }}
            >
                <PageHeader
                    title={() => {
                        return (
                            <Stack
                                direction="row"
                                sx={{
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigate('/enterprise');
                                }}
                            >
                                <i className="fa-regular fa-arrow-left fa-2xl"></i>
                                <Typography
                                    sx={{
                                        marginLeft: 2,
                                        fontFamily: 'Nocturne Serif',
                                        fontSize: '2.5em',
                                        fontWeight: 400,
                                        lineHeight: '1em',
                                        letterSpacing: '-0.4px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Campaigns
                                </Typography>
                            </Stack>
                        );
                    }}
                />

                <Stack
                    direction="column"
                    spacing={4}
                    // sx={{
                    // }}
                >
                    <Stack
                        direction="column"
                        spacing={0}
                        // sx={{
                        // }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                p: 1,
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '17px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '1px',
                                    textAlign: 'left',
                                }}
                            >
                                {users?.Count ?? 0} {(users?.Count ?? 0) === 1 ? 'Member' : 'Members'}
                            </Typography>
                            <Button
                                variant="irdbText"
                                onClick={() => {
                                    setShowManageMembers(true);
                                }}
                            >
                                Manage
                            </Button>
                        </Stack>
                        <Paper
                            sx={{
                                borderRadius: 2,
                                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                            }}
                            elevation={0}
                        >
                            <Stack
                                direction={{ xs: 'column', md: 'row' }}
                                spacing={0}
                                // sx={{
                                // }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        maxHeight: 'fit-content',
                                        // flexGrow: 1,
                                        // backgroundColor: 'coral',
                                    }}
                                >
                                    <FileDropArea
                                        dropzoneOptions={{
                                            onDrop,
                                            accept: imageAccept,
                                            disabled: !isEditingEnabled,
                                        }}
                                    >
                                        <>
                                            <Box
                                                component="img"
                                                sx={{
                                                    width: { xs: '100%', md: 280 },
                                                    height: { xs: 200, md: 280 },
                                                    objectFit: 'contain',
                                                }}
                                                src={campaignImageUrl ?? DefaultCampaignImageUrl}
                                                alt=""
                                            />
                                            {isEditingEnabled && (
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        // fontSize: '1rem',
                                                        bottom: '1rem',
                                                        right: '1rem',
                                                    }}
                                                    icon="fa-pen"
                                                    // onClick={() => {
                                                    //     // onCopy(content);
                                                    // }}
                                                />
                                            )}
                                        </>
                                    </FileDropArea>
                                </Box>
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    sx={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        alignItems: 'flex-start',
                                        justifyContent: 'center',
                                        px: 4,
                                        pt: 3,
                                        pb: 0,

                                        // backgroundColor: 'lime',
                                    }}
                                >
                                    <Stack
                                        // direction={{ xs: 'column', md: 'row' }}
                                        direction="column"
                                        spacing={2}
                                        sx={{
                                            display: 'flex',
                                            flexGrow: 1,
                                            width: '100%',
                                            // backgroundColor: 'coral',
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Select
                                                options={[
                                                    { value: 'publish', label: 'Published' },
                                                    { value: 'draft', label: 'Draft' },
                                                ]}
                                                value={'publish'}
                                                // value={imageStatus}
                                                // onChange={async (value) => {
                                                //     setShowLoading(true);
                                                //     await status(image.imageID, value as 'publish' | 'draft');
                                                //     const i = await load(image.imageID);
                                                //     setImage(i);
                                                //     setShowLoading(false);
                                                // }}
                                            />
                                            {id !== 'new' && (
                                                <Button
                                                    disabled={isProcessing}
                                                    sx={{
                                                        color: darkMode ? Color.White : Color.Black,
                                                    }}
                                                    onClick={editButtonProps.onClick}
                                                >
                                                    <i
                                                        className={`fa-regular ${editButtonProps.iconClassName}`}
                                                        style={{
                                                            marginRight: '0.5em',
                                                        }}
                                                    ></i>{' '}
                                                    {editButtonProps.label}
                                                </Button>
                                            )}
                                        </Stack>

                                        {isEditingEnabled ?
                                            <Stack
                                                direction="column"
                                                spacing={2}
                                                sx={{
                                                    display: 'flex',
                                                    flexGrow: 1,

                                                    // backgroundColor: 'coral',
                                                }}
                                            >
                                                <TextField
                                                    label="Name"
                                                    value={campaignName ?? ''}
                                                    onChange={value => setCampaignName(value)}
                                                />
                                                <TextField
                                                    label="Description"
                                                    value={campaignDescription ?? ''}
                                                    onChange={value => setCampaignDescription(value)}
                                                />
                                                <Stack
                                                    direction="row"
                                                    // spacing={1}
                                                    sx={{
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Button
                                                        variant="irdbGradient"
                                                        onClick={() => {
                                                            // console.log('Save');
                                                            save();
                                                        }}
                                                        disabled={!campaignName}
                                                    >
                                                        Save Changes
                                                    </Button>
                                                    {id !== 'new' && (
                                                        <Button
                                                            variant="irdbText"
                                                            sx={{
                                                                color: '#ff0000',
                                                            }}
                                                            onClick={handleDeleteCampaign}
                                                        >
                                                            Delete Campaign
                                                        </Button>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        :   <Stack
                                                direction="column"
                                                spacing={1}
                                                sx={{
                                                    display: 'flex',
                                                    flexGrow: 1,
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Nocturne Serif',
                                                        fontSize: '32px',
                                                        fontWeight: 700,
                                                        lineHeight: '34px',
                                                        letterSpacing: '-0.4px',
                                                    }}
                                                >
                                                    {campaignName}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Nunito Sans',
                                                        fontSize: '20px',
                                                        fontWeight: 400,
                                                        lineHeight: '24px',
                                                        letterSpacing: '0.4px',
                                                    }}
                                                >
                                                    {campaignDescription}
                                                </Typography>
                                            </Stack>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Stack>

                    {id && id !== 'new' && (
                        <>
                            <BulkUploader
                                onStart={() => {
                                    setIsProcessing(true);
                                }}
                                onComplete={() => {
                                    setIsProcessing(false);
                                }}
                                onAdd={async (imageId: string) => {
                                    try {
                                        await addImage(campaignID, imageId);
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }}
                            />

                            {!isProcessing && (
                                <>
                                    <Paper
                                        sx={{
                                            p: 1,
                                            borderRadius: 2,
                                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White, // Color.LightLavenderLightMode,
                                        }}
                                        elevation={0}
                                    >
                                        <Tabs
                                            value={tab}
                                            onChange={(event: SyntheticEvent, newValue: number) => {
                                                setTab(newValue);
                                            }}
                                            // onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                        >
                                            {tabs.map(tab => (
                                                <Tab key={tab} label={tab} />
                                            ))}
                                        </Tabs>

                                        {currentTab === TabOption.Analytics && (
                                            <AnalyticsTop campaignID={campaignID} campaignCreated={campaignCreated} />
                                        )}

                                        {currentTab === TabOption.Ircodes && (
                                            <Stack
                                                direction="column"
                                                spacing={2}
                                                sx={{
                                                    padding: 2,
                                                }}
                                            >
                                                <OwnedIrcodes
                                                    // images={images}
                                                    isProcessing={isProcessing}
                                                    fetchImages={fetchCampaignImagesCallback}
                                                    onEdit={(imageId: string) => {
                                                        navigateAndScrollToTop(
                                                            `/enterprise/campaigns/${id}/ircodes/${imageId}#information`,
                                                        );
                                                    }}
                                                    removeImageFromCampaign={removeImageFromCampaign}
                                                    remove={remove}
                                                />
                                            </Stack>
                                        )}

                                        {currentTab === TabOption.Products && <Products campaignID={campaignID} />}
                                    </Paper>

                                    {currentTab === TabOption.Analytics && <></>}

                                    {currentTab === TabOption.Ircodes && (
                                        // <IrcodeInformationBottom
                                        //     image={image}
                                        //     meta={meta}
                                        //     onUpdate={async () => {
                                        //         setShowLoading(true);
                                        //         const i = await load(image.imageID);
                                        //         setImage(i);
                                        //         setShowLoading(false);
                                        //     }}
                                        // />
                                        <></>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Stack>
            </Stack>
            <Dialog
                open={showManageMembers}
                onClose={() => setShowManageMembers(false)}
                sx={{
                    '& .MuiDialog-paper': {
                        // width: '60vw',
                        width: { xs: '100vw', md: '60vw' },
                        minHeight: '50vh',
                        maxHeight: '80vh',
                        p: { xs: 0, md: 1 },
                        borderRadius: 2,
                    },
                }}
            >
                <Stack
                    direction="column"
                    // spacing={2}
                    sx={
                        {
                            // backgroundColor: 'lime',
                            // p: 2,
                            // position: 'relative',
                        }
                    }
                >
                    <IconButton
                        icon="fa-xmark"
                        sx={{
                            // position: 'absolute',
                            // top: -16,
                            // left: -16,
                            backgroundColor: 'transparent',
                        }}
                        onClick={() => {
                            setShowManageMembers(false);
                        }}
                    />
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            // backgroundColor: 'lime',
                            p: 4,
                            // position: 'relative',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                fontSize: '24px',
                                fontWeight: 700,
                                lineHeight: '24px',
                                letterSpacing: '-0.4px',
                                textAlign: 'left',
                            }}
                        >
                            Manage Members
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '18px',
                                letterSpacing: '0.4px',
                                textAlign: 'left',
                            }}
                        >
                            When you give people permissions on your campaign, they can view, edit and take other
                            actions.
                        </Typography>

                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                // justifyContent: 'center',
                            }}
                        >
                            <TextField
                                label="Email Address"
                                value={invitationEmail ?? ''}
                                onChange={value => setInvitationEmail(value)}
                                sx={{
                                    // TODO: Fina a better way to make textfields vertically align with other things
                                    pb: 2,
                                }}
                            />
                            <Select
                                options={permissions}
                                value={invitationPermission}
                                onChange={value => {
                                    const permission = value as Permission;
                                    setInvitationPermission(permission);
                                }}
                                sx={{
                                    flexGrow: 1,
                                }}
                            />
                            <Button
                                variant="irdbGradient"
                                onClick={async () => {
                                    try {
                                        const response = await invite(
                                            campaignID,
                                            invitationEmail,
                                            invitationPermission,
                                        );

                                        fetchCampaignUsersCallback(campaignID);
                                    } catch (error) {
                                        notify('Error', String.fromError(error));
                                    }
                                }}
                            >
                                Invite
                                <i className="fa-regular fa-arrow-right"></i>
                            </Button>
                        </Stack>

                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                letterSpacing: '-0.4px',
                                textAlign: 'left',
                            }}
                        >
                            Members ({users?.Count ?? 0})
                        </Typography>
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{
                                display: 'flex',
                                flexGrow: 1,
                            }}
                        >
                            {(users?.Results ?? []).map(user => {
                                // let permission = '2';
                                // switch (true) {
                                //     case user.superUser === 1:
                                //         permission = '1';
                                //         break;
                                //     default:
                                //         permission = '2';
                                //         break;
                                // }

                                return (
                                    <Stack
                                        key={user.amID}
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar
                                            src={nullUndefinedOrEmpty(user.profileUrl) ? undefined : user.profileUrl}
                                            sx={{
                                                width: 26,
                                                height: 26,
                                                borderRadius: 13,
                                            }}
                                        />
                                        <Stack
                                            direction="column"
                                            sx={{
                                                display: 'flex',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Typography
                                                sx={
                                                    {
                                                        // fontFamily: 'Nocturne Serif',
                                                        // fontSize: '32px',
                                                        // fontWeight: 600,
                                                        // lineHeight: '40px',
                                                        // letterSpacing: '-0.03em',
                                                        // textAlign: 'left',
                                                    }
                                                }
                                            >
                                                {user.userName}
                                            </Typography>
                                            <Typography
                                                sx={
                                                    {
                                                        // fontFamily: 'Nocturne Serif',
                                                        // fontSize: '32px',
                                                        // fontWeight: 600,
                                                        // lineHeight: '40px',
                                                        // letterSpacing: '-0.03em',
                                                        // textAlign: 'left',
                                                    }
                                                }
                                            >
                                                {/* user@irdb.com */}
                                            </Typography>
                                        </Stack>
                                        <Select
                                            options={permissions}
                                            value={user.permission}
                                            onChange={async value => {
                                                // setSelectedCampaign(campaigns.find(c => c.value === value));

                                                setShowLoading(true);
                                                await updatePermission(
                                                    campaignID,
                                                    user.userID,
                                                    user.controllingUserID,
                                                    value as Permission,
                                                );
                                                await fetchCampaignUsersCallback(campaignID);
                                                setShowLoading(false);
                                            }}
                                            sx={{
                                                width: 200,
                                            }}
                                        />
                                        <IconButton
                                            icon="fa-xmark"
                                            sx={{}}
                                            onClick={async () => {
                                                if (
                                                    await confirm({
                                                        title: 'Remove Member',
                                                        message: 'Are you sure you want to remove this member?',
                                                        yes: 'Remove',
                                                        no: 'Cancel',
                                                        destructive: true,
                                                    })
                                                ) {
                                                    setShowLoading(true);
                                                    await deletePermission(
                                                        user.amID,
                                                        campaignID,
                                                        user.userID,
                                                        user.controllingUserID,
                                                    );
                                                    await fetchCampaignUsersCallback(campaignID);
                                                    setShowLoading(false);
                                                }
                                            }}
                                        />
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    );
}
