import { AccountPrivacyForm, AccountPrivacyInfo } from '../../../shared/Profile/AccountPrivacy';
import useBooleanState from '../../../../hooks/useBooleanState';
import { Dialog, Stack } from '@mui/material';

const AccountPrivacy = () => {
    const [showAccountInfo, { setTrue: setShowAccountInfo, setFalse: setHideAccountInfo }] = useBooleanState(false);
    return (
        <>
            <AccountPrivacyForm onInfo={setShowAccountInfo} />
            <Dialog open={showAccountInfo} onClose={setHideAccountInfo}>
                <Stack rowGap={1}>
                    <AccountPrivacyInfo sx={{ maxWidth: '400px', flexGrow: 1, pb: 3, borderRadius: 'inherit' }} />
                </Stack>
            </Dialog>
        </>
    );
};

export default AccountPrivacy;
