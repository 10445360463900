import { Card, Stack, Typography, Box, IconButton, Button, Checkbox, Dialog } from '@mui/material';
import { ImageOperation, Query } from 'src/contexts/MediaContext';
import { Select } from '../../general/Select';
import { memo, useCallback, useContext, useRef, useState } from 'react';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { Color } from 'src/Color';
import { MetaContent, MetaType, CardType, TCardType } from 'src/types/MetaTypes';
import { TitleForm } from '../pages/MetaData/Title';
import { ArtistNameForm } from '../pages/MetaData/ArtistName';
import { LinkForm } from '../pages/MetaData/Link';
import { indexForMetaType, metaFieldForMetaType, newMetaField } from 'src/contexts/MetaContext';
import { CardTypeForm } from '../pages/MetaData/CardType';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import Cropper from 'src/components/image/CropperExtended';
import { WithMetaErrorsProvider } from 'src/contexts/specialized/MetaErrorsContext';
import useMetaErrors from 'src/hooks/metaErrors/useMetaErrors';
import { arePropertiesEqual } from 'src/util/objects';
import useBulkUploaderState from '../../../stores/useBulkUploaderState';

import { BulkUploaderStore } from '../../../stores/BulkUploader/store';

interface AvailableItemProps {
    imageOperation: ImageOperation<Query>;
    darkMode?: boolean;
    replaceImageOperation: (imageOperation: ImageOperation<any>) => void;
    removeImageOperation: (imageOperation: ImageOperation<any>) => void;
    onCrop: (imageOperation: ImageOperation<any> | null) => void;
    onCopyMetaField: (metaType: MetaType, metaContent: MetaContent) => void;
    onCopy: (imageOperation: ImageOperation<any>, metaType?: MetaType) => void;
}

const AvailableItem = memo(
    WithMetaErrorsProvider(function AvailableItem({
        imageOperation,
        darkMode,
        removeImageOperation,
        replaceImageOperation,
        onCrop,
        onCopyMetaField,
        onCopy,
    }: AvailableItemProps) {
        {
            const { confirm } = useContext(FeedbackContext) as TFeedback;
            const imgSrc = imageOperation.cropped?.preview ?? imageOperation.original?.preview;
            const meta = imageOperation.bulkOperation?.meta ?? [];

            const onChange = useCallback(
                (metaType: MetaType, metaContent: MetaContent) => {
                    const newAvailableIrcode = {
                        ...imageOperation,
                        bulkOperation: {
                            ...imageOperation.bulkOperation!,
                            meta: [...imageOperation.bulkOperation!.meta],
                        },
                    };
                    const index = indexForMetaType(meta, metaType);
                    if (index !== -1) {
                        newAvailableIrcode.bulkOperation!.meta![index].metaContent = metaContent;
                    } else {
                        newAvailableIrcode.bulkOperation!.meta!.push(newMetaField(metaType, metaContent));
                    }
                    replaceImageOperation(newAvailableIrcode);
                },
                [replaceImageOperation],
            );

            const cardType =
                (metaFieldForMetaType(meta, MetaType.CardType)?.metaContent as TCardType | undefined)?.cardType ??
                CardType.ArtCard;

            return (
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        checked={imageOperation.bulkOperation?.selected ?? true}
                        onChange={event => {
                            const newAvailableIrcode = structuredClone(imageOperation);
                            newAvailableIrcode.bulkOperation!.selected = event.target.checked;
                            replaceImageOperation(newAvailableIrcode);
                        }}
                    />
                    <Stack
                        direction="row"
                        spacing={0}
                        sx={{
                            flexGrow: 1,
                            alignItems: 'center',
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                        }}
                    >
                        <Stack direction="column" spacing={1}>
                            <Box
                                component="img"
                                src={imgSrc}
                                alt=""
                                sx={{
                                    flexBasis: 140,
                                    width: 140,
                                    height: 140,
                                    borderTopLeftRadius: { xs: '8px', sm: '8px' },
                                    borderTopRightRadius: { xs: '8px', sm: 0 },
                                    borderBottomLeftRadius: { xs: 0, sm: '8px' },
                                    borderBottomRightRadius: { xs: 0, sm: 0 },
                                    // objectFit: 'cover',
                                    objectFit: 'contain',
                                    overflow: 'hidden',
                                }}
                                loading="lazy"
                            />
                            <Stack
                                spacing={0}
                                sx={{
                                    // pl: 2,
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Button
                                    sx={{
                                        color: darkMode ? Color.White : Color.Black,
                                        opacity: 0.8,
                                        whiteSpace: 'nowrap',
                                    }}
                                    onClick={async () => {
                                        onCrop(imageOperation);
                                    }}
                                >
                                    <i
                                        className="fa-regular fa-crop fa-fw"
                                        style={{
                                            marginRight: '0.5em',
                                        }}
                                    ></i>{' '}
                                    Crop
                                </Button>
                                <Button
                                    sx={{
                                        color: darkMode ? Color.White : Color.Black,
                                        opacity: 0.8,
                                        whiteSpace: 'nowrap',
                                    }}
                                    onClick={async () => {
                                        if (
                                            await confirm({
                                                title: 'Confirm Copy',
                                                message: 'Copy all metadata across to other IRCODES in this view?',
                                            })
                                        ) {
                                            onCopy(imageOperation);
                                        }
                                    }}
                                >
                                    <i
                                        className="fa-regular fa-copy fa-fw"
                                        style={{
                                            marginRight: '0.5em',
                                        }}
                                    ></i>{' '}
                                    Copy All
                                </Button>
                                <Button
                                    sx={{
                                        color: darkMode ? Color.White : Color.Black,
                                        opacity: 0.8,
                                        whiteSpace: 'nowrap',
                                    }}
                                    onClick={async () => {
                                        if (
                                            await confirm({
                                                title: 'Confirm Copy',
                                                message: 'Copy all links across to other IRCODES in this view?',
                                            })
                                        ) {
                                            onCopy(imageOperation, MetaType.Link);
                                        }
                                    }}
                                >
                                    <i
                                        className="fa-regular fa-copy fa-fw"
                                        style={{
                                            marginRight: '0.5em',
                                        }}
                                    ></i>{' '}
                                    Copy All Links
                                </Button>
                                {/*
                                <Button
                                    sx={{
                                        color: darkMode ? Color.White : Color.Black,
                                        opacity: 0.8,
                                    }}
                                    onClick={async () => {
                                        if (
                                            await confirm({
                                                title: 'Confirm Copy',
                                                message: 'Copy all product links across to other IRCODES in this view?',
                                            })
                                        ) {
                                            copyImageOperationMetaData(imageOperation, imageOperations, replaceImageOperation, MetaType.ProductLink);
                                        }
                                    }}
                                >
                                    <i
                                        className="fa-regular fa-copy"
                                        style={{
                                            marginRight: '0.5em',
                                        }}
                                    ></i>{' '}
                                    Copy All Products
                                </Button>
                            */}
                            </Stack>
                        </Stack>

                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                flexGrow: 1,
                                alignItems: 'center',
                                p: 2,
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing={1}
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <CardTypeForm
                                    metaField={metaFieldForMetaType(meta, MetaType.CardType)}
                                    onCopy={async metaContent => {
                                        onCopyMetaField(MetaType.CardType, metaContent);
                                    }}
                                    onChange={(metaContent: MetaContent) => {
                                        onChange(MetaType.CardType, metaContent);
                                    }}
                                />
                                <TitleForm
                                    metaField={metaFieldForMetaType(meta, MetaType.Title)}
                                    onCopy={async metaContent => {
                                        onCopyMetaField(MetaType.Title, metaContent);
                                    }}
                                    onChange={(metaContent: MetaContent) => {
                                        onChange(MetaType.Title, metaContent);
                                    }}
                                />
                                {cardType === CardType.ArtCard && (
                                    <ArtistNameForm
                                        metaField={metaFieldForMetaType(meta, MetaType.ArtistName)}
                                        onCopy={async metaContent => {
                                            onCopyMetaField(MetaType.ArtistName, metaContent);
                                        }}
                                        onChange={(metaContent: MetaContent) => {
                                            onChange(MetaType.ArtistName, metaContent);
                                        }}
                                    />
                                )}
                                <LinkForm
                                    metaField={metaFieldForMetaType(meta, MetaType.Link)}
                                    onChange={(metaContent: MetaContent) => {
                                        onChange(MetaType.Link, metaContent);
                                    }}
                                />
                            </Stack>

                            <Stack>
                                {imageOperation.operation.Results?.ImageAlreadyExists && (
                                    <Stack
                                        sx={{
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            mb: 3,
                                            zIndex: 1,
                                            '&:hover .match-tooltip': {
                                                display: 'block',
                                            },
                                        }}
                                        onClick={() => {
                                            !imageOperation.operation.Results?.ImagePrivate &&
                                                window.open(
                                                    `/dashboard/ircodes/${imageOperation.operation.Results?.Image?.imageID}`,
                                                    '_blank',
                                                );
                                        }}
                                    >
                                        <i
                                            className="fa-solid fa-triangle-exclamation fa-fw"
                                            style={{
                                                display: 'inline-block',
                                                fontSize: '1.2em',
                                                marginBottom: 1,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: darkMode ? Color.White : Color.Black,
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: 400,
                                                lineHeight: '1.3em',
                                                letterSpacing: '0.01em',
                                                opacity: 0.8,
                                            }}
                                        >
                                            IRCODE may exist <br />
                                            (hover for info)
                                        </Typography>
                                        <Box
                                            className="match-tooltip"
                                            sx={{
                                                background: darkMode ? '#323241' : Color.White,
                                                position: 'absolute',
                                                top: '-2em',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                border: '1px solid ' + Color.Purple,
                                                borderRadius: 2,
                                                padding: '.5em 1em',
                                                display: 'none',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: darkMode ? Color.White : Color.Black,
                                                    fontFamily: 'Nunito Sans',
                                                    fontWeight: 400,
                                                    lineHeight: '1.5em',
                                                    letterSpacing: '0.01em',
                                                }}
                                            >
                                                Possible match:
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                        flexShrink: 0,
                                                        width: 130,
                                                        height: 100,
                                                        objectFit: 'cover',
                                                    }}
                                                    src={imageOperation.operation.Results?.Image?.imageUrl}
                                                    alt=""
                                                />
                                                {imageOperation.operation.Results?.Image?.metaContent?.title ??
                                                    'Untitled'}
                                                {!imageOperation.operation.Results?.ImagePrivate && (
                                                    <i className="fa-solid fa-arrow-right" style={{ marginLeft: 8 }} />
                                                )}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                )}

                                <Select
                                    options={[
                                        { value: 'publish', label: 'Publish' },
                                        { value: 'draft', label: 'Draft' },
                                    ]}
                                    value={imageOperation.bulkOperation!.status}
                                    onChange={value => {
                                        const newAvailableIrcode = structuredClone(imageOperation);
                                        newAvailableIrcode.bulkOperation!.status = value as 'publish' | 'draft';
                                        replaceImageOperation(newAvailableIrcode);
                                    }}
                                    sx={{
                                        width: 150,
                                    }}
                                />
                            </Stack>

                            <IconButton
                                onClick={() => {
                                    removeImageOperation(imageOperation);
                                }}
                            >
                                <i className="fa-regular fa-trash fa-xs"></i>
                            </IconButton>
                        </Stack>
                    </Stack>
                </Stack>
            );
        }
    }),
    (prevProps, nextProps) => {
        return (
            arePropertiesEqual(
                prevProps,
                nextProps,
                'darkMode',
                'onCopy',
                'onCopyMetaField',
                'onCrop',
                'replaceImageOperation',
                'removeImageOperation',
            ) &&
            arePropertiesEqual(prevProps.imageOperation, nextProps.imageOperation, 'id', 'cropped', 'original') &&
            arePropertiesEqual(
                prevProps.imageOperation.bulkOperation,
                nextProps.imageOperation.bulkOperation,
                'meta',
                'selected',
            ) &&
            arePropertiesEqual(
                prevProps.imageOperation.operation,
                nextProps.imageOperation.operation,
                'status',
                'Completed',
            )
        );
    },
);

interface Props {
    registerSelectedImages: () => void;
}

const registerDisabledSelector = (state: BulkUploaderStore) =>
    state.isProcessing ||
    state.hasPending ||
    state.hasAdding ||
    !state.availableIrcodes.some(imageOperation => !!imageOperation.bulkOperation?.selected);

const Available = memo(function Available({ registerSelectedImages }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const useBulkUploaderSelectors = useBulkUploaderState().use;
    const imageOperations = useBulkUploaderSelectors.availableIrcodes();
    const replaceImageOperation = useBulkUploaderSelectors.replaceImageOperation();
    const removeImageOperation = useBulkUploaderSelectors.removeImageOperation();
    const onCopy = useBulkUploaderSelectors.onCopy();
    const onCopyMetaField = useBulkUploaderSelectors.onCopyAvailableMetaFieldData();

    const [cropperImageOperation, setCropperImageOperation] = useState<ImageOperation<any> | null>(null);

    const availableStackRef = useRef<HTMLDivElement>(null);

    const hasMetaErrors = useMetaErrors(state => state.hasErrors.length > 0);

    const registerDisabled = useBulkUploaderSelectors(registerDisabledSelector);

    const isRegisterDisabled = registerDisabled || hasMetaErrors;

    if (imageOperations.length === 0) {
        return null;
    } else {
        return (
            <Card
                elevation={0}
                sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                }}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    ref={availableStackRef}
                    sx={{
                        scrollMargin: 16,
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <i className="fa-solid fa-check fa-2xl" style={{ marginRight: '16px', color: '#B0DB48' }}></i>
                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                fontSize: '24px',
                                fontWeight: 400,
                                lineHeight: '26px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}
                        >
                            {imageOperations.length} available for registration
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',
                                textAlign: 'left',
                            }}
                        >
                            Enter a title to register a new IRCODE
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            style={{ marginLeft: 'auto' }}
                            onClick={() => {
                                if (availableStackRef.current) {
                                    availableStackRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                                }
                            }}
                        >
                            Scroll to Bottom
                        </Typography>
                    </Stack>
                    {imageOperations.map(imageOperation => (
                        <AvailableItem
                            key={imageOperation.id}
                            imageOperation={imageOperation}
                            replaceImageOperation={replaceImageOperation}
                            removeImageOperation={removeImageOperation}
                            onCrop={setCropperImageOperation}
                            onCopyMetaField={onCopyMetaField}
                            onCopy={onCopy}
                            validationId={imageOperation.id}
                            darkMode={darkMode}
                        />
                    ))}
                    <Button
                        sx={{
                            opacity: isRegisterDisabled ? 0.5 : 1,
                        }}
                        disabled={isRegisterDisabled}
                        variant="irdbGradient"
                        onClick={() => {
                            registerSelectedImages();
                        }}
                    >
                        Register selected images
                    </Button>
                </Stack>
                <Dialog
                    open={!!cropperImageOperation}
                    sx={{
                        '& .MuiDialog-paper': {
                            minWidth: '80vw',
                        },
                    }}
                >
                    <Cropper
                        imageOperation={cropperImageOperation}
                        onProgress={(progress: ImageOperation<any>) => {}}
                        onSuccess={async i => {
                            replaceImageOperation(i);
                            setCropperImageOperation(null);
                        }}
                        onRetake={() => {
                            setCropperImageOperation(null);
                        }}
                        onCancel={() => {
                            setCropperImageOperation(null);
                        }}
                    />
                </Dialog>
            </Card>
        );
    }
});

export default Available;
