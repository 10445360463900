import { PickByType } from '../../../types/util';
import { useProfileInfo } from '../../../stores/useProfileState';
import { Props as TextFieldProps } from '../../general/TextField';
import { useCallback } from 'react';
import ProfileTextField, { ProfileTextFieldProps } from './ProfileTextField';

import { TAccount } from '../../../stores/ProfileInfoStore';

type TAccountStringPropertyKey = keyof PickByType<TAccount, string>;
export type StatefulProfileTextFieldProps<T extends TAccountStringPropertyKey> = Omit<
    ProfileTextFieldProps,
    'value' | 'onChange'
> & {
    stateKey: T;
    label: string;
    mobileView?: boolean;
    onChange?: TextFieldProps['onChange'];
};
const mobileTextFieldSx = {
    '.MuiOutlinedInput-input': {
        py: 1.5,
    },
};

const StatefulProfileTextField = <T extends TAccountStringPropertyKey>({
    stateKey,
    onChange,
    mobileView,
    ...rest
}: StatefulProfileTextFieldProps<T>) => {
    const useSelectors = useProfileInfo().use;
    const value = useSelectors[stateKey]?.();
    const setInfo = useSelectors.setInfo();
    const setValue = useCallback((value: string) => setInfo({ [stateKey]: value }), [stateKey, setInfo]);

    return (
        <ProfileTextField
            {...rest}
            value={value ?? ''}
            onChange={value => {
                setValue(value);
                onChange?.(value);
            }}
            sx={{ ...(mobileView && mobileTextFieldSx), ...rest.sx }}
        />
    );
};

export default StatefulProfileTextField;
