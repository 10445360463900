export type ErrorMessage = string;

export enum Type {
    Init = 'Init',
    Prep = 'Prep',
    Shrink = 'Shrink',
    Foveate = 'Foveate',
    Upload = 'Upload',
    Query = 'Query',
    Crop = 'Crop',
    Add = 'Add',
    Replace = 'Replace',
    // Load = 'Load'
    Completed = 'Completed',
}

export interface Operation<T> {
    type: Type;
    // TODO: Type this, no idea what it can be
    status: string;
    Pending: boolean;

    // These are filled in by the API for network based operations
    // Completed is like "success"
    Completed: boolean;
    Results?: T;

    ErrorMessage?: ErrorMessage;
}
