import { Stack, Typography } from '@mui/material';
import { Color } from 'src/Color';
import { Metric, TimeframeAndInterval } from 'src/types/Analytics';
import { useCallback, useContext, useEffect, useState } from 'react';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import useIrdbAnalytics, { Totals } from 'src/hooks/useIrdbAnalytics';

interface Props {
    campaignID: number;
    metric: Metric;
    timeframeAndInterval?: TimeframeAndInterval;

    // Keen
    // timeframe: Timeframe;
    // interval: Interval;

    // IRDB
    // days: number;
    // unit: string; // wanted to call this interval but it conflicts with the Keen interval
    start?: string;
    end?: string;
}

export default function TotalsChart({ campaignID, metric, timeframeAndInterval, start, end }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { fetchTotals } = useIrdbAnalytics();

    const [totals, setTotals] = useState<Totals>();

    const pctDisplay = useCallback((pct: number | undefined) => {
        return Math.floor((pct ?? 0) * 100);
    }, []);

    const fetchAnalytics = useCallback(() => {
        console.log('[ANALYTICS] fetchAnalytics', campaignID, timeframeAndInterval, start, end, metric);

        return new Promise<Totals>((resolve, reject) => {
            fetchTotals({
                campaignID,
                metric,
                timeframe: timeframeAndInterval?.days,
                interval: timeframeAndInterval?.unit ?? 'days',
                start,
                end,
                // aggregated: true,
                totals: true,
            })
                .then(result => {
                    console.log('results', result);
                    resolve(result);
                })
                .catch((error: any) => {
                    console.error('error', error);
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, timeframeAndInterval, start, end, metric]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        fetchAnalytics()
            .then(totals => {
                setTotals(totals);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [fetchAnalytics, campaignID]);

    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{}}>
            {/* Views */}
            <Stack
                direction="column"
                sx={{
                    width: '100%',

                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            color: darkMode ? '#B8AABB' : Color.DarkModeBlack,
                        }}
                    >
                        Views
                    </Typography>
                    <i className="fa-light fa-eye" style={{ color: darkMode ? '#B8AABB' : Color.DarkModeBlack }} />
                </Stack>
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                        }}
                    >
                        {totals?.totalRecent}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            color:
                                pctDisplay(totals?.percentTotal) > 0 ? Color.AnalyticsGreen
                                : pctDisplay(totals?.percentTotal) < 0 ? Color.AnalyticsRed
                                : darkMode ? Color.White
                                : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        {pctDisplay(totals?.percentTotal)}%
                    </Typography>
                </Stack>
            </Stack>

            {/* Unique Users */}
            <Stack
                direction="column"
                sx={{
                    width: '100%',

                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            color: darkMode ? '#B8AABB' : Color.DarkModeBlack,
                        }}
                    >
                        Unique Users
                    </Typography>
                    <i className="fa-light fa-user" style={{ color: darkMode ? '#B8AABB' : Color.DarkModeBlack }} />
                </Stack>
                <Stack
                    direction="row"
                    sx={{
                        // flexGrow: 2,
                        // alignContent: 'center',
                        // space-between: 'center',

                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                        }}
                    >
                        {totals?.totalUnique}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            color:
                                pctDisplay(totals?.percentUnique) > 0 ? Color.AnalyticsGreen
                                : pctDisplay(totals?.percentUnique) < 0 ? Color.AnalyticsRed
                                : darkMode ? Color.White
                                : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        {pctDisplay(totals?.percentUnique)}%
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}
