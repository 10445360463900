import React, { useContext } from 'react';
import { Box, Button, Typography, Stack, Dialog, Avatar } from '@mui/material';
import UserContext, { TUser } from '../../contexts/UserContext';
import { UserSearchResults } from '../../contexts/AdminContext';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import { Color } from 'src/Color';

interface PasswordResetDialogProps {
    open: boolean;
    onClose: () => void;
    selectedUsers: UserSearchResults[];
}

export default function PasswordResetEmail({ open, onClose, selectedUsers }: PasswordResetDialogProps) {
    const { sendPasswordResetEmail } = useContext(UserContext) as TUser;
    const { notify } = useContext(FeedbackContext) as TFeedback;

    const handlePasswordReset = async () => {
        try {
            await Promise.allSettled(selectedUsers.map(user => sendPasswordResetEmail(user.email)));

            await notify('Password reset email(s) sent successfully.');
            onClose();
        } catch (error) {
            console.error('Failed to send password reset email(s):', error);
            alert('Failed to send some or all password reset emails.');
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Box p={2}>
                <Typography>Send password reset email to the following user(s)?</Typography>
                <Stack spacing={1} mt={2}>
                    {selectedUsers.map(user => (
                        <Box key={user.userID} sx={{ mb: 2, borderBottom: '1px solid', pb: 1 }}>
                            <Avatar src={user.profileUrl} />
                            <Typography>Username: {user.userName}</Typography>
                            <Typography>Full Name: {user.fullName}</Typography>
                            <Typography>Email: {user.email}</Typography>
                        </Box>
                    ))}
                </Stack>
                <Stack direction="row" spacing={2} mt={3}>
                    <Button variant="webTextButton" sx={{ color: Color.Purple }} onClick={handlePasswordReset}>
                        Send Password Reset
                    </Button>
                    <Button variant="webTextButton" sx={{ color: 'red' }} onClick={onClose}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Dialog>
    );
}
