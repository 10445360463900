import { useContext, useEffect } from 'react';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import FirebaseContext, { TFirebase } from 'src/contexts/FirebaseContext';
import UserContext, { TUser } from 'src/contexts/UserContext';
import { Quality, shrink } from 'src/util/image';
import { validateName, validateUsername } from 'src/util/string';
import { getPasswordValidState } from '../hooks/usePasswordValidator';
import { FirebaseError } from 'firebase/app';
import { getFBAuthErrorMessage, firebaseAuthErrorMessages } from '../util/firebase';
import ProfileInfoContext from '../contexts/specialized/ProfileInfoContext';
import { TAccount } from './ProfileInfoStore';

const initProfileInfo: Omit<TAccount, 'setInfo'> = {
    fullName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    profileUrl: '',
    newPassword: '',
    confirmNewPassword: '',
    currentPassword: '',
    avatarFile: null,
    authProviders: { password: false, google: false, apple: false },
    passwordValidState: {
        length: false,
        lowerCaseLetter: false,
        upperCaseLetter: false,
        number: false,
        specialCharacter: false,
    },
};

export const useProfileInfo = () => {
    const storeApi = useContext(ProfileInfoContext);
    if (!storeApi) {
        throw new Error('useProfileInfo must be used within a ProfileInfoProvider');
    }
    return storeApi;
};

export const useProfileActions = () => {
    const { setShowLoading, notify, confirm } = useContext(FeedbackContext) as TFeedback;
    const {
        user,
        // addProfileAvatar,
        removeProfileAvatar,
        editProfile,
        updatePassword,
        deleteUser,
        reauthenticateWithPassword,
    } = useContext(UserContext) as TUser;
    const { upload } = useContext(FirebaseContext) as TFirebase;
    const storeApi = useProfileInfo();
    const setInfo = storeApi.use.setInfo();

    const updateInfoFromCurrentUser = () => {
        setInfo({
            fullName: user?.fullName ?? '',
            userName: user?.userName ?? '',
            email: user?.email ?? '',
            phoneNumber: user?.phone ?? '',
            profileUrl: user?.profileUrl,
            newPassword: '',
            confirmNewPassword: '',
            authProviders: user?.authProviders,
            avatarFile: null,
        });
    };

    const clearInfo = () => {
        setInfo(initProfileInfo);
    };

    useEffect(
        () =>
            storeApi.subscribe((state, oldState) => {
                let finalState: Partial<TAccount> | undefined;
                if (state.newPassword !== oldState.newPassword) {
                    finalState = { passwordValidState: getPasswordValidState(state.newPassword) };
                }
                if (state.avatarFile && state.avatarFile !== oldState.avatarFile) {
                    finalState = {
                        ...(finalState ?? {
                            profileUrl: URL.createObjectURL(state.avatarFile),
                        }),
                    };
                }
                if (finalState) {
                    storeApi.setState(finalState);
                }
            }),
        [storeApi],
    );

    // const handleAvatarUpload = async () => {
    //    const info = useProfileInfoStore.getState();
    //     if (!info.avatarFile) {
    //         return;
    //     }
    //     setShowLoading(true);
    //     shrink(info.avatarFile, Quality.High)
    //         .then(shrunkenFile => {
    //             return upload(
    //                 shrunkenFile.file,
    //                 'profile',
    //                 progress => {
    //                 });
    //         })
    //         .then(downloadUrl => {
    //             setInfo({ profileUrl: downloadUrl});
    //             return addProfileAvatar(downloadUrl);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         })
    //         .finally(() => {
    //             setShowLoading(false);
    //             setInfo({ avatarFile: null });
    //         });
    // };

    const handleAddAvatar = (file: File) => {
        setInfo({ avatarFile: file });
    };

    const handleRemoveAvatar = async () => {
        const { avatarFile } = storeApi.getState();
        if (avatarFile) {
            setInfo({ avatarFile: null, profileUrl: user?.profileUrl });
            return;
        }
        setInfo({ profileUrl: undefined });
    };

    const saveProfile = async () => {
        const info = storeApi.getState();
        let profileUrl = info.profileUrl;
        if (info.avatarFile) {
            const shrunk = await shrink(info.avatarFile, Quality.High);
            profileUrl = await upload(shrunk.file, 'profile', progress => {});
            if (info.profileUrl) {
                URL.revokeObjectURL(info.profileUrl);
            }
            setInfo({ avatarFile: null, profileUrl });
        } else if (!info.profileUrl && user?.profileUrl) {
            profileUrl = undefined;
            await removeProfileAvatar();
            setInfo({ profileUrl });
        }
        const { fullName, userName, email, phoneNumber } = info;
        await editProfile({ fullName, userName, email, phone: phoneNumber, profileUrl });
    };

    /** Returns true if password was changed */
    const changePassword = async () => {
        const { newPassword, confirmNewPassword, currentPassword, reauthenticationRequired } = storeApi.getState();
        if (newPassword === '') {
            return false;
        }
        if (newPassword !== confirmNewPassword) {
            throw new Error('Passwords do not match');
        }
        if (reauthenticationRequired) {
            if (currentPassword === '') {
                throw new FirebaseError('auth/requires-recent-login', 'auth/requires-recent-login');
            }
            await reauthenticateWithPassword(currentPassword);
        }
        await updatePassword(newPassword);
        return true;
    };

    /** Returns true if the profile was saved successfully */
    const onSave = async () => {
        const info = storeApi.getState();
        if (!validateName(info.fullName)) {
            notify('Problem saving changes', 'Names cannot contain special characters.');
            return false;
        }
        if (!validateUsername(info.userName)) {
            notify(
                'Problem saving changes',
                'Username has to be between 5 to 15 characters long. Only letters, numbers, dashes, periods and underscores are allowed.',
            );
            return false;
        }
        if (info.newPassword && !Object.values(info.passwordValidState).every(Boolean)) {
            notify('Problem saving changes', 'Please enter a valid password.');
            return false;
        }
        if (info.newPassword !== info.confirmNewPassword) {
            notify('Problem saving changes', 'Passwords do not match.');
            return false;
        }
        if (info.reauthenticationRequired && info.newPassword && !info.currentPassword) {
            notify('Problem saving changes', 'Please enter your current password.');
            return false;
        }
        const confirmation = await confirm({
            title: 'Save Changes',
            message: 'Are you sure you want to save changes to your profile?',
        });
        let requiresReauthentication = info.reauthenticationRequired;
        if (confirmation) {
            try {
                setShowLoading(true);
                await saveProfile();
                const didChangePassword = await changePassword();
                if (didChangePassword) {
                    requiresReauthentication = false;
                }
                await notify('Success', 'Profile updated successfully');
                return true;
            } catch (error: any) {
                let errorMessage;
                if (error instanceof FirebaseError) {
                    const fbErrorMessage = getFBAuthErrorMessage(error);
                    if (fbErrorMessage) {
                        errorMessage = fbErrorMessage;
                    }
                }
                if (!errorMessage) {
                    errorMessage = 'Unknown error';
                } else if (errorMessage === firebaseAuthErrorMessages['auth/requires-recent-login']) {
                    requiresReauthentication = true;
                }
                await notify('Problem saving changes', errorMessage);
                return false;
            } finally {
                const newInfo: Partial<TAccount> = {
                    reauthenticationRequired: requiresReauthentication,
                    ...(!requiresReauthentication && { newPassword: '', confirmNewPassword: '' }),
                };
                setInfo(newInfo);

                setShowLoading(false);
            }
        }
        return false;
    };

    const onDeleteUser = async () => {
        if (await confirm({ title: 'IRCODE', message: 'Are you sure you want to delete your account?' })) {
            try {
                // await remove(image.imageID);
                await deleteUser();

                // await signOut();
                // TODO: Try replace = true
                // navigateTo('/dashboard', true);
                // Using navigater caused the page to sit at a spinner. This is a workaround.
                document.location.href = '/dashboard';
            } catch (error) {
                // console.error("Error signing out:", error);
            }
        }
    };

    return {
        setInfo,
        // handleAvatarUpload,
        handleAddAvatar,
        handleRemoveAvatar,
        changePassword,
        onSave,
        onDeleteUser,
        updateInfoFromCurrentUser,
        clearInfo,
    };
};
