import React, { useState } from 'react';
import {
    Stack,
    FormControl,
    TextField,
    Button,
    Dialog,
    Typography,
    DialogActions,
    Accordion,
    AccordionSummary,
} from '@mui/material';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import { Color } from '../../Color';

interface EditUserProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setOpenEditUserDialog: (open: boolean) => void;
    selectedUsers: UserSearchResults[];
    onClose: () => void;
}

export default function EditUser({ setOpenEditUserDialog, selectedUsers, onClose }: EditUserProps) {
    const { editAdminProfile, analyticsRefresh } = React.useContext(AdminContext) as TAdmin;

    const [userInputs, setUserInputs] = useState<UserSearchResults[]>(selectedUsers);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, userID: number) => {
        const { name, value } = e.target;

        setUserInputs(prevInputs =>
            prevInputs.map(user => {
                if (user.userID === userID) {
                    switch (name) {
                        case 'userName':
                            return { ...user, userName: value };
                        case 'fullName':
                            return { ...user, fullName: value };
                        case 'email':
                            return { ...user, email: value };
                        case 'phone':
                            return { ...user, phone: value };
                        default:
                            return user;
                    }
                }
                return user;
            }),
        );
    };

    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleSubmit = async () => {
        try {
            await Promise.allSettled(
                userInputs.map(async user => {
                    const selectedUser = selectedUsers.find(u => u.userID === user.userID);
                    if (selectedUser && selectedUser.FBuserID) {
                        await editAdminProfile(
                            user.email,
                            user.fullName,
                            user.userName,
                            user.phone,
                            user.FBuserID,
                            user.userID,
                        );
                    } else {
                        console.error('User ID or Facebook ID missing');
                    }
                }),
            );
            setOpenEditUserDialog(false);
            handleCloseConfirmDialog();
            analyticsRefresh();
            onClose();
        } catch (error) {
            console.error('Error updating users:', error);
        }
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                m: 'auto',
                p: 4,
                borderRadius: '8px',
                backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
            }}
        >
            <FormControl component="form" onSubmit={handleSubmit}>
                <Typography variant="mainFont9" sx={{ color: Color.PrimaryLavender, p: 2 }}>
                    Edit User Information
                </Typography>
                {userInputs.map(user => (
                    <Accordion key={user.userID}>
                        <AccordionSummary expandIcon={<i className="fa-sharp fa-light fa-arrow-up" />}>
                            <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender, p: 2 }}>
                                {' '}
                                {user.userName}
                            </Typography>
                        </AccordionSummary>
                        <Stack direction="column" spacing={2} sx={{ p: 2 }}>
                            <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                                Edit {user.userName}
                            </Typography>
                            <TextField
                                label="User Name"
                                name="userName"
                                value={user.userName}
                                InputLabelProps={{ shrink: true }}
                                placeholder={selectedUsers.find(u => u.userID === user.userID)?.userName || ''}
                                onChange={e => handleInputChange(e, user.userID)}
                                sx={{ p: 1 }}
                            />
                            <TextField
                                label="Name"
                                name="fullName"
                                value={user.fullName}
                                InputLabelProps={{ shrink: true }}
                                placeholder={selectedUsers.find(u => u.userID === user.userID)?.fullName || ''}
                                onChange={e => handleInputChange(e, user.userID)}
                                sx={{ p: 1 }}
                            />
                            <TextField
                                label="Email"
                                name="email"
                                value={user.email}
                                InputLabelProps={{ shrink: true }}
                                placeholder={selectedUsers.find(u => u.userID === user.userID)?.email || ''}
                                onChange={e => handleInputChange(e, user.userID)}
                                sx={{ p: 1 }}
                            />
                            <TextField
                                label="Phone"
                                name="phone"
                                value={user.phone}
                                InputLabelProps={{ shrink: true }}
                                placeholder={selectedUsers.find(u => u.userID === user.userID)?.phone || ''}
                                onChange={e => handleInputChange(e, user.userID)}
                                sx={{ p: 1 }}
                            />
                        </Stack>
                    </Accordion>
                ))}

                <Button
                    onClick={handleOpenConfirmDialog}
                    sx={{
                        marginTop: 2,
                        color: Color.White,
                        backgroundColor: Color.PrimaryLavender,
                        '&:hover': { backgroundColor: Color.PalePurple },
                    }}
                >
                    Submit
                </Button>
            </FormControl>
            <Button
                onClick={() => setOpenEditUserDialog(false)}
                sx={{
                    marginTop: 2,
                    color: Color.White,
                    backgroundColor: Color.PrimaryLavender,
                    '&:hover': { backgroundColor: Color.PalePurple },
                }}
            >
                Close
            </Button>

            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        m: 'auto',
                        p: 4,
                        borderRadius: '8px',
                        backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                        boxShadow: 1,
                    }}
                >
                    <Typography sx={{ color: Color.PrimaryLavender, variant: 'webLable2', p: 2 }}>
                        Confirm User Information
                    </Typography>
                    {userInputs.map(user => (
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{ p: 2, borderBottom: '1px solid', borderColor: Color.PrimaryLavender }}
                            key={user.userID}
                        >
                            <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                                User Name: {user.userName}
                            </Typography>
                            <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                                Name: {user.fullName}
                            </Typography>
                            <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                                Email: {user.email}
                            </Typography>
                            <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                                Phone: {user.phone}
                            </Typography>
                        </Stack>
                    ))}
                    <DialogActions>
                        <Button
                            onClick={handleCloseConfirmDialog}
                            sx={{ color: Color.White, backgroundColor: Color.PrimaryLavender }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            sx={{ color: Color.White, backgroundColor: Color.PrimaryLavender }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
        </Stack>
    );
}
