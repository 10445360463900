import { StateCreator } from 'zustand';
import { BulkUploaderStore } from '../store';
import { arePropertiesEqual } from '../../../util/objects';
import { getImageOperationAtIndex } from '../../../contexts/specialized/BulkUploaderContext';
import { Operation, Type } from '../../../contexts/Operation';
import Timespan from 'src/util/timespan';

export interface ProgressSlice {
    isProcessing: boolean;
    isCanceling: boolean;
    processingCount: number;
    processedCount: number;
    timeEstimation: number | null;
    timeEstimationTimespan: Timespan | null;
    progress: number | null;

    setIsProcessing(isProcessing: boolean): void;
    setIsCanceling(isCanceling: boolean): void;
    setProcessingCount(processingCount: number): void;
    setProcessedCount(processedCount: number): void;
    setTimeEstimation(timeEstimation: number | null): void;
    setProgress(progress: number | null): void;

    incrementProcessedCount(amount?: number): void;
    incrementProcessingCount(amount?: number): void;
}

const getOperationProgress = (operation?: Operation<any>): number => {
    if (!operation) {
        return 0;
    }
    switch (operation.type) {
        case Type.Prep:
            return 25;
        case Type.Upload:
            return (operation.Results?.progress ?? 50) as number;
        case Type.Foveate:
            return 50;
        case Type.Query:
        case Type.Add:
            // TODO: Queries take a long time, should at least try and give them some weight
            return 100;
        default:
            return 100;
    }
};

const handleGetNewProgress = (state: BulkUploaderStore, prevState: BulkUploaderStore): number | undefined => {
    if (arePropertiesEqual(state, prevState, 'pendingIrcodes', 'addingIrcodes', 'processingCount', 'processedCount')) {
        return;
    }
    const { progress, imageOperationsCount, processingCount, processedCount } = state;
    if (progress === null) {
        return 0;
    }
    if (processingCount === 0 || imageOperationsCount === 0) {
        return 0;
    }
    if (processedCount >= processingCount && processingCount !== 1) {
        return 100;
    }
    const currentProcessing = getImageOperationAtIndex(state, processedCount - 1);
    if (!currentProcessing) {
        return;
    }
    const currentProgress = getOperationProgress(currentProcessing.operation);
    const progressPerOperation = 100 / processingCount;
    const newProgress = (processedCount - 1) * progressPerOperation + currentProgress / processingCount;
    if (newProgress > progress) {
        return newProgress;
    }
};

const handleGetTimeEstimationTimespan = (state: BulkUploaderStore, prevState: BulkUploaderStore) => {
    if (arePropertiesEqual(state, prevState, 'timeEstimation', 'processingCount', 'pendingIrcodes', 'addingIrcodes'))
        return;
    if (state.timeEstimation === null) {
        if (state.processingCount < 3) {
            return new Timespan(Math.max(1, state.processingCount - state.processedCount) * 3000);
        }
        return null;
    }
    if (state.timeEstimation) {
        return new Timespan(state.timeEstimation);
    }
};

const handleGetIsCanceling = (state: BulkUploaderStore, prevState: BulkUploaderStore): boolean | undefined => {
    if (arePropertiesEqual(state, prevState, 'imageOperationsCount')) return;
    const hasImageOperations = state.imageOperationsCount > 0;
    const hadImageOperations = prevState.imageOperationsCount > 0;
    if (hasImageOperations && !hadImageOperations && state.isCanceling) {
        return false;
    }
};

const createProgressSlice: StateCreator<BulkUploaderStore, [], [], ProgressSlice> = (set, get, store) => {
    const slice: ProgressSlice = {
        isProcessing: false,
        isCanceling: false,
        processingCount: 0,
        processedCount: 1,
        timeEstimation: null,
        timeEstimationTimespan: null,
        progress: null,

        setIsProcessing: isProcessing => set({ isProcessing }),
        setIsCanceling: isCanceling => set({ isCanceling }),
        setProcessingCount: processingCount => set({ processingCount }),
        setProcessedCount: processedCount => set({ processedCount }),
        setTimeEstimation: timeEstimation => set({ timeEstimation }),
        setProgress: progress => set({ progress }),

        incrementProcessedCount: (amount = 1) =>
            set(state => ({ processedCount: Math.min(state.processingCount, state.processedCount + amount) })),
        incrementProcessingCount: (amount = 1) => set(state => ({ processingCount: state.processingCount + amount })),
    };
    store.subscribe((state, prevState) => {
        let result: Partial<ProgressSlice> | null = null;
        const progress = handleGetNewProgress(state, prevState);
        if (progress !== undefined) {
            result = { progress: progress };
        }
        const timeEstimationTimespan = handleGetTimeEstimationTimespan(state, prevState);
        if (
            (timeEstimationTimespan === null && state.timeEstimationTimespan !== null) ||
            (timeEstimationTimespan &&
                (state.timeEstimationTimespan === null || !timeEstimationTimespan.equals(state.timeEstimationTimespan)))
        ) {
            console.log('setting timeEstimationTimespan');
            result ??= {};
            result.timeEstimationTimespan = timeEstimationTimespan;
        }
        const isCanceling = handleGetIsCanceling(state, prevState);
        if (isCanceling !== undefined) {
            result ??= {};
            result.isCanceling = isCanceling;
        }
        if (result) {
            set(result);
        }
    });
    return slice;
};

export default createProgressSlice;
