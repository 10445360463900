import React, { useContext, useState } from 'react';
import {
    Stack,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Avatar,
} from '@mui/material';
import AdminContext, { AdminType, TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import { Color } from '../../Color';


interface ModAdminProps {
    handleAdminChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setOpenAdminModDialog: (open: boolean) => void;
    selectedUsers: UserSearchResults[];
    onClose: () => void;
}

export default function ModAdmin({ setOpenAdminModDialog, selectedUsers, onClose }: ModAdminProps) {
    const { modifyAdminType, analyticsRefresh } = useContext(AdminContext) as TAdmin;
    const [value, setValue] = useState<AdminType>(AdminType.NONE);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const getAdminType = (value: AdminType) => {
        switch (value) {
            case AdminType.SUPPORT:
                return AdminType.SUPPORT;
            case AdminType.ADMIN:
                return AdminType.ADMIN;
            default:
                return AdminType.NONE;
        }
    };

    const handleConfirmation = async () => {
        if (selectedUsers.length > 0) {
            try {
                const adminType = getAdminType(value);

                const results = await Promise.all(
                    selectedUsers.map(user => {
                        if (user.userID && user.FBuserID) {
                            return modifyAdminType(user.userID, user.FBuserID, adminType);
                        } else {
                            console.error('User ID or Firebase ID is null for:', user.userName);
                            return Promise.resolve(false);
                        }
                    }),
                );

                onClose();
                analyticsRefresh();
                alert('Admin types updated successfully.');
            } catch (error) {
                console.error('Failed to update admin type for one or more users.');
            }

            setOpenAdminModDialog(false);
            setOpenConfirmationDialog(false);
        } else {
            console.error('No selected users to update.');
        }
    };

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                m: 'auto',
                p: 4,
                borderRadius: '8px',
                backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                boxShadow: 1,
            }}
        >
            <FormControl>
                <Typography  variant= 'mainFont9'>
                    Change User Admin Type
                </Typography>
    
                <RadioGroup value={value} onChange={e => setValue(e.target.value as AdminType)} sx={{p:2}}>
                    <FormControlLabel
                        value={AdminType.NONE}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                None
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value={AdminType.SUPPORT}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Support
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value={AdminType.ADMIN}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Admin
                            </Typography>
                        }
                    />
                </RadioGroup>
                <Button
                    onClick={() => setOpenConfirmationDialog(true)}
                    sx={{
                        color: Color.White,
                        backgroundColor: Color.PrimaryLavender,
                        '&:hover': { backgroundColor: Color.PalePurple },
                    }}
                >
                    Submit
                </Button>
            </FormControl>
            <Button
                onClick={() => setOpenAdminModDialog(false)}
                sx={{
                    color: Color.White,
                    backgroundColor: Color.PrimaryLavender,
                    '&:hover': { backgroundColor: Color.PalePurple },
                }}
            >
                Close
            </Button>

            <Dialog open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
                <Box sx={{ p: 2 }}>
                    <Typography variant='mainFont9'>Confirm Change to below users to {value}</Typography>
                    {selectedUsers.map(user => (
                        <Box key={user.userID} sx={{ p: 2, borderBottom: '1px solid', pb: 1 }}>
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row" spacing={1}>
                                <Avatar src={user.profileUrl} />
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="lineDataLabel">Name: {user.fullName}</Typography>
                                    <Typography variant="lineDataLabel">Email: {user.email}</Typography>
                                <Typography variant="lineDataLabel">Username: {user.userName}</Typography>
                                </Stack>
                           </Stack>
                            </Stack>
                        </Box>
                    ))}
                     <Stack direction="row" spacing={2} mt={2}>

                        <Button onClick={handleConfirmation} variant="webTextButton">Yes</Button>
                        <Button onClick={handleCloseConfirmationDialog} variant="webTextButton">No</Button>
                    </Stack>
                </Box>
            </Dialog>
        </Stack>
    );
}
