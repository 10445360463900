import Papa from 'papaparse';
import useApi, { Method } from './useApi';
import { PagedResults } from 'src/contexts/MediaContext';
import { Image, ImageID } from '../types/Image';
import { useSaveMeta } from './meta/useSaveMeta';
import { MetaType, TCardType, TCompany, TEmail, TFirstName, TJobTitle, TLastName, TTitle } from '../types/MetaTypes';
import { ILink, TLink } from '../types/Link';

enum AttendeeListColumns {
    ImageID = 'Image ID',
    FirstName = 'First Name',
    LastName = 'Last Name',
    Company = 'Company',
    JobTitle = 'Job Title',
    Email = 'Email',
    LinkedIn = 'LinkedIn',
}

interface Attendee {
    imageID?: ImageID;
    firstName: string;
    lastName: string;
    company: string;
    jobTitle: string;
    email: string;
    linkedIn?: string;
}

const readAttendeesCsvFromFile = async (file: File): Promise<Attendee[]> => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            complete: function (results) {
                if (results.data.length < 2) {
                    throw new Error('CSV needs a row of keys and one or more rows of data.');
                }

                const data = results.data as string[][];
                const keys = data.shift() as string[];
                const keysMap = keys.reduce(
                    (agg, curr, index) => {
                        agg[curr] = index;
                        return agg;
                    },
                    {} as Record<string, number>,
                );

                const attendees: Attendee[] = data
                    .map(row => {
                        if (row.length !== keys.length) {
                            // Field count doesn't match, skip.
                            return null;
                        }

                        return {
                            firstName: row[keysMap[AttendeeListColumns.FirstName]],
                            lastName: row[keysMap[AttendeeListColumns.LastName]],
                            company: row[keysMap[AttendeeListColumns.Company]],
                            jobTitle: row[keysMap[AttendeeListColumns.JobTitle]],
                            email: row[keysMap[AttendeeListColumns.Email]],
                            linkedIn: row[keysMap[AttendeeListColumns.LinkedIn]],
                        } as Attendee;
                    })
                    // Filter files where the file is null (ie field count is wrong)
                    .filter(n => n) as Attendee[];
                resolve(attendees);
            },
        });
    });
};

export default function useAttendeeList() {
    const { request } = useApi();
    const { save } = useSaveMeta();

    const create = async (): Promise<PagedResults<{ Image: Image }>> => {
        try {
            const response = await request({
                method: Method.PUT,
                path: `/IREngine/imageless`,
            });
            // console.log('response', response);

            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const massageFile = async (file: File): Promise<File> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async function (e) {
                if (!e.target?.result) {
                    reject(new Error('No result found'));
                    return;
                }

                const result = new TextDecoder('utf-8').decode(new Uint8Array(e.target.result as ArrayBuffer));
                const rows = Papa.parse(result, { header: true }).data as Array<Record<string, string>>;
                const dataRows = rows.filter(row => Object.values(row).some(value => value.trim() !== ''));

                const processedData = dataRows.map(row => {
                    const [fullName, company, jobTitle, email, linkedIn] = Object.values(row).map(field =>
                        field.replace(/^"|"$/g, '').replace(/\r/g, '').trim(),
                    );
                    const [firstName, ...lastNameParts] = fullName.split(' ');
                    const lastName = lastNameParts.join(' ');
                    return [firstName, lastName, jobTitle, company, email, linkedIn];
                });

                const newHeaders = ['First Name', 'Last Name', 'Job Title', 'Company', 'Email', 'LinkedIn'];
                const newCsvContent = [newHeaders, ...processedData]
                    .map(row => row.map(field => `"${field?.replace(/"/g, '""')}"`).join(','))
                    .join('\n');

                const newFile = new File([newCsvContent], file.name, {
                    type: file.type,
                });

                resolve(newFile);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const metaFromAttendee = (attendee: Attendee) => {
        return [
            {
                key: crypto.randomUUID(),
                metaID: 0,
                metaType: MetaType.CardType,
                metaContent: {
                    cardType: 'contact',
                } as TCardType,
            },
            {
                key: crypto.randomUUID(),
                metaID: 0,
                metaType: MetaType.Title,
                metaContent: {
                    title: `${attendee.firstName} ${attendee.lastName}`,
                } as TTitle,
            },
            {
                key: crypto.randomUUID(),
                metaID: 0,
                metaType: MetaType.FirstName,
                metaContent: {
                    firstName: attendee.firstName,
                } as TFirstName,
            },
            {
                key: crypto.randomUUID(),
                metaID: 0,
                metaType: MetaType.LastName,
                metaContent: {
                    lastName: attendee.lastName,
                } as TLastName,
            },
            {
                key: crypto.randomUUID(),
                metaID: 0,
                metaType: MetaType.Email,
                metaContent: {
                    email: attendee.email,
                    isPublic: false,
                } as TEmail,
            },
            {
                key: crypto.randomUUID(),
                metaID: 0,
                metaType: MetaType.Company,
                metaContent: {
                    company: attendee.company,
                } as TCompany,
            },
            {
                key: crypto.randomUUID(),
                metaID: 0,
                metaType: MetaType.JobTitle,
                metaContent: {
                    jobTitle: attendee.jobTitle,
                } as TJobTitle,
            },
            {
                key: crypto.randomUUID(),
                metaID: 0,
                metaType: MetaType.Link,
                metaContent: {
                    links:
                        attendee.linkedIn ?
                            [
                                {
                                    title: 'LinkedIn',
                                    url: attendee.linkedIn,
                                    onScanDisplay: false,
                                } as ILink,
                            ]
                        :   [],
                } as TLink,
            },
        ];
    };

    const loadAttendees = async (file: File) => {
        const massagedFile = await massageFile(file);
        const attendeesFromFile = await readAttendeesCsvFromFile(massagedFile);

        const attendees = await Promise.allSettled(
            attendeesFromFile.slice(0).map(attendee => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const results = await create();
                        const image = results.Results;

                        await save(image.Image.imageID, metaFromAttendee(attendee));

                        resolve({
                            ...attendee,
                            imageID: image.Image.imageID,
                        });
                    } catch (error) {
                        reject(error);
                    }
                });
            }),
        ).then(results => {
            return results.map(result => (result.status === 'fulfilled' ? result.value : null)).filter(Boolean);
        });

        const csv = Papa.unparse(attendees);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.download = 'data.csv';
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return {
        loadAttendees,
    };
}
