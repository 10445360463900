import React, { useContext, useState } from 'react';
import {
    Stack,
    FormControl,
    FormLabel,
    RadioGroup,
    Typography,
    FormControlLabel,
    Radio,
    Button,
    Dialog,
    Avatar,
    Box,
} from '@mui/material';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import { Color } from '../../Color';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';

enum AccountType {
    Anonymous = 'Anonymous',
    Basic = 'Basic',
    Pro = 'Pro',
    Enterprise = 'Enterprise',
}

interface ModAccountProps {
    value: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setOpenAccountModDialog: (open: boolean) => void;
    onClose: () => void;
    selectedUsers: UserSearchResults[];
}

export default function ModAccount({
    value,

    handleChange,
    setOpenAccountModDialog,
    onClose,
    selectedUsers,
}: ModAccountProps) {
    const { modifyAccountType, analyticsRefresh } = useContext(AdminContext) as TAdmin;
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [newType, setNewType] = useState('');
    const { notify } = useContext(FeedbackContext) as TFeedback;

    const handleSubmit = async () => {
        setNewType(value);
        setConfirmDialog(true);
    };

    const handleConfirm = async () => {
        try {
            const updates = await Promise.allSettled(
                selectedUsers.map(async user => {
                    return modifyAccountType(user.userID, user.FBuserID, value);
                }),
            );
            notify('Account Types Changed');
            analyticsRefresh();
            setNewType('');
            setOpenAccountModDialog(false);
            setConfirmDialog(false);
            onClose();
        } catch (error) {
            notify('Failed to modify account types.');
        }
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                m: 'auto',
                p: 4,
                borderRadius: '8px',
                backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                boxShadow: 1,
            }}
        >
            <FormControl>
                <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    sx={{ color: Color.PrimaryLavender, variant: 'mainFont9' }}
                >
                    Upgrade/Downgrade Account Type
                </FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="account-type"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value={AccountType.Anonymous}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Anonymous
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value={AccountType.Basic}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Basic
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value={AccountType.Pro}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Pro
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value={AccountType.Enterprise}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Enterprise
                            </Typography>
                        }
                    />
                </RadioGroup>
                <Button
                    onClick={handleSubmit}
                    sx={{
                        color: Color.White,
                        backgroundColor: Color.PrimaryLavender,
                        '&:hover': { backgroundColor: Color.PalePurple },
                    }}
                >
                    Submit
                </Button>
            </FormControl>
            <Button
                onClick={() => handleCancel()}
                sx={{
                    color: Color.White,
                    backgroundColor: Color.PrimaryLavender,
                    '&:hover': { backgroundColor: Color.PalePurple },
                }}
            >
                Close
            </Button>

            <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
                <Box sx={{ p: 2 }}>
                    <Typography variant="mainFont9" sx={{ p: 2 }}>
                        Confirm Account Type Change to {newType}
                    </Typography>

                    {selectedUsers.map(user => (
                        <Box key={user.userID} sx={{ p: 2, borderBottom: '1px solid', pb: 1 }}>
                            <Stack direction="column" spacing={1}>
                                <Avatar src={user.profileUrl} />
                                <Typography variant="lineDataLabel">Username: {user.userName}</Typography>
                                <Typography variant="lineDataLabel">Full Name: {user.fullName}</Typography>
                                <Typography variant="lineDataLabel">Current User Type: {user.userType}</Typography>
                            </Stack>
                        </Box>
                    ))}
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button onClick={() => setConfirmDialog(false)} variant="webTextButton">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} color="primary" variant="webTextButton">
                            Confirm
                        </Button>
                    </Stack>
                </Box>
            </Dialog>
        </Stack>
    );
}
