import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Stack,
    Typography,
    Box,
    TextField,
    InputAdornment,
    SelectChangeEvent,
    MenuItem,
    Select,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Checkbox,
    TableContainer,
} from '@mui/material';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import EnterpriseContext, { Campaign, TEnterprise } from 'src/contexts/EnterpriseContext';
import { PagedResults } from 'src/contexts/MediaContext';
import MyCampaignRow from './MyCampaignRow';
import Pagination from 'src/components/general/Pagination';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import useAuth from 'src/hooks/useAuth';
import ManageMembersDialog from './ManageMembersDialog';
import EventContext, { TEvent, Event } from 'src/contexts/EventContext';
import usePageTitle from 'src/hooks/usePageTitle';
import { Color } from 'src/Color';

type SortField = keyof Campaign;

export default function Campaigns() {
    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { fetch, deleteCampaign, removeCampaignImages, update } = useContext(EnterpriseContext) as TEnterprise;
    const { setSuccessMessage, setErrorMessage, confirm } = useContext(FeedbackContext) as TFeedback;
    const [isProcessing, setIsProcessing] = useState(false);
    const [campaigns, setCampaigns] = useState<PagedResults<Campaign[]>>();
    const [sortField, setSortField] = useState<SortField>('created');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const [itemsCount, setItemsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
    const [bulkAction, setBulkAction] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showManageMembers, setShowManageMembers] = useState(false);
    const [selectedManage, setSelectedManage] = useState<Campaign | undefined>(undefined);
    const { subscribe } = useContext(EventContext) as TEvent;
    const [manageQueue, setManageQueue] = useState<string[]>([]);

    const upArrow = <i className="fa-sharp fa-light fa-angle-up" />;
    const downArrow = <i className="fa-sharp fa-light fa-angle-down" />;

    const fetchAndSet = async () => {
        const campaigns = await fetch();
        setCampaigns(campaigns);
    };

    const deleteCampaignAndImages = async (campaignID: number, removeImages = false) => {
        if (removeImages) {
            await removeCampaignImages(campaignID);
        }
        await deleteCampaign(campaignID);
    };

    useAuth(() => {
        setIsProcessing(true);
        fetchAndSet()
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setIsProcessing(false);
            });
    }, [fetch, refresh, manageQueue]);

    useEffect(() => {
        const refreshCampaignsListener = () => {
            setRefresh(prev => !prev);
        };
        const unsubscribe = subscribe(Event.RefreshCampaigns, refreshCampaignsListener);

        return () => {
            unsubscribe();
        };
    }, [subscribe, manageQueue]);

    useEffect(() => {
        if (manageQueue.length > 0 && !selectedManage) {
            const nextCampaign = campaigns?.Results.find(campaign => manageQueue[0] === campaign.campaignID.toString());
            setSelectedManage(nextCampaign || undefined);
        }
    }, [manageQueue, campaigns?.Results, selectedManage]);

    const handleSort = (field: SortField | 'user') => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortField(field);
    };

    const sortedCampaigns = (campaigns?.Results ?? []).sort((a, b) => {
        let aValue: string | number = '';
        let bValue: string | number = '';

        if (sortField === 'user') {
            aValue = a.user?.[0]?.userName?.toLowerCase() || '';
            bValue = b.user?.[0]?.userName?.toLowerCase() || '';
        } else {
            aValue = (a[sortField] as string | number) ?? '';
            bValue = (b[sortField] as string | number) ?? '';
        }

        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        } else {
            return (sortDirection === 'asc' ? 1 : -1) * aValue.toString().localeCompare(bValue.toString());
        }
    });

    const handleSearchSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setCurrentPage(1);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        setCurrentPage(1);
    };

    const handleSelectAll = () => {
        setSelectAll(prevSelectAll => {
            const newSelectAll = !prevSelectAll;
            setSelectedCampaigns(
                newSelectAll ? (campaigns?.Results ?? []).map(campaign => campaign.campaignID.toString()) : [],
            );
            return newSelectAll;
        });
    };

    const filteredCampaigns = sortedCampaigns.filter(campaign =>
        campaign.campaignName.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    const paginatedCampaigns = filteredCampaigns.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (value: number) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const handleSelectCampaign = (campaignID: string) => {
        setSelectedCampaigns(prevSelected =>
            prevSelected.includes(campaignID) ?
                prevSelected.filter(id => id !== campaignID)
            :   [...prevSelected, campaignID],
        );
    };

    const resetSelections = () => {
        setSelectedCampaigns([]);
        setSelectAll(false);
        setBulkAction('');
    };

    const handleBulkPublish = async () => {
        if (
            await confirm({
                title: 'Publish Campaigns',
                message: 'Are you sure you want to publish the selected campaigns?',
                yes: 'Publish',
                no: 'Cancel',
                destructive: true,
            })
        ) {
            try {
                await Promise.all(
                    selectedCampaigns.map(campaignID => {
                        const campaign = campaigns?.Results.find(c => c.campaignID === Number(campaignID));
                        if (campaign) {
                            return update({ ...campaign, campaignStatus: 'publish' });
                        }
                        return Promise.resolve();
                    }),
                );
                resetSelections();
                fetchAndSet();
                setSuccessMessage('Campaigns published successfully');
            } catch (error) {
                setErrorMessage('Failed to publish campaigns');
            }
        }
        setOpenConfirm(false);
    };

    const handleBulkDraft = async () => {
        if (
            await confirm({
                title: 'Draft Campaigns',
                message: 'Are you sure you want to draft the selected campaigns?',
                yes: 'Draft',
                no: 'Cancel',
                destructive: true,
            })
        ) {
            try {
                await Promise.all(
                    selectedCampaigns.map(campaignID => {
                        const campaign = campaigns?.Results.find(c => c.campaignID === Number(campaignID));
                        if (campaign) {
                            return update({ ...campaign, campaignStatus: 'draft' });
                        }
                        return Promise.resolve();
                    }),
                );
                resetSelections();
                fetchAndSet();
                setSuccessMessage('Campaigns drafted successfully');
            } catch (error) {
                setErrorMessage('Failed to draft campaigns');
            }
        }
        setOpenConfirm(false);
    };

    const handleBulkActionChange = async (event: SelectChangeEvent<string>) => {
        const action = event.target.value as string;
        setBulkAction(action);

        try {
            switch (action) {
                case 'delete':
                    await handleBulkDelete();
                    break;
                case 'publish':
                    await handleBulkPublish();
                    break;
                case 'draft':
                    await handleBulkDraft();
                    break;
                case 'manage':
                    handleBulkManage();
                    break;
                default:
                    break;
            }
        } finally {
            setBulkAction('');
        }
    };

    const handleBulkManage = () => {
        const selectedCampaignsList =
            campaigns?.Results.filter(campaign => selectedCampaigns.includes(campaign.campaignID.toString())) || [];
        if (selectedCampaignsList.length > 0) {
            setManageQueue(selectedCampaigns);
            const nextCampaign = selectedCampaignsList[0];
            setSelectedManage(nextCampaign);
            setShowManageMembers(true);
        }
    };
    const handleBulkDelete = async () => {
        if (
            await confirm({
                title: 'Delete Campaigns',
                message: 'Are you sure you want to delete the selected campaigns?',
                yes: 'Delete',
                no: 'Cancel',
                destructive: true,
            })
        ) {
            const hasMedia = selectedCampaigns.some(
                campaignID => campaigns?.Results.find(c => c.campaignID === Number(campaignID))?.media,
            );
            let removeIrcodes = false;
            if (hasMedia) {
                removeIrcodes = await confirm({
                    title: 'Delete Campaign IRCODES?',
                    message: 'Do you want to also delete all IRCODES you owned that were in the selected campaigns?',
                    yes: 'Yes, Delete IRCODES',
                    no: 'No, Keep IRCODES',
                    destructive: true,
                });
            }
            try {
                await Promise.all(
                    selectedCampaigns.map(campaignID => deleteCampaignAndImages(Number(campaignID), removeIrcodes)),
                );
                resetSelections();
                fetchAndSet();
                setSuccessMessage('Campaigns deleted successfully');
            } catch (error) {
                setErrorMessage('Failed to delete campaigns');
            }
        }
        setOpenConfirm(false);
    };

    usePageTitle('Campaigns');

    return (
        <Stack direction="column">
            <Stack
                direction={{ sm: 'column', md: 'row' }}
                spacing={2}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    pb: 4,
                }}
            >
                <Typography sx={{ flexGrow: 1 }}>
                    {(currentPage - 1) * itemsPerPage + 1}-
                    {Math.min(currentPage * itemsPerPage, filteredCampaigns.length)} of {campaigns?.Results.length ?? 0}{' '}
                    Items
                </Typography>
                <form onSubmit={handleSearchSubmit}>
                    <TextField
                        label="Filter..."
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: '8px',
                                    }}
                                >
                                    <Button
                                        sx={{
                                            minWidth: 'auto',
                                            padding: 0,
                                        }}
                                    >
                                        <i className="fa-sharp fa-light fa-magnifying-glass"></i>
                                    </Button>
                                    <Button
                                        onClick={handleClearSearch}
                                        sx={{
                                            minWidth: 'auto',
                                            padding: 0,
                                        }}
                                    >
                                        <i className="fa-sharp fa-light fa-xmark"></i>
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
                <Select
                    value={bulkAction}
                    onChange={handleBulkActionChange}
                    displayEmpty
                    disabled={selectedCampaigns.length === 0}
                >
                    <MenuItem value="" disabled>
                        Bulk Actions
                    </MenuItem>
                    <MenuItem value="manage">Add Member</MenuItem>
                    <MenuItem value="draft">Mark as Draft</MenuItem>
                    <MenuItem value="publish">Mark as Published</MenuItem>
                    <MenuItem value="delete">Delete</MenuItem>
                </Select>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'none', sm: 'none', md: 'block' },
                    }}
                />
                <Button
                    variant="irdbGradient"
                    sx={{
                        width: 200,
                    }}
                    onClick={() => {
                        navigateAndScrollToTop('/enterprise/campaigns/new');
                    }}
                >
                    Create a Campaign
                </Button>
            </Stack>
            {isProcessing && (
                <Stack
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress />
                </Stack>
            )}
            <TableContainer
                sx={{
                    flexGrow: 1,
                }}
            >
                <Table stickyHeader>
                    <TableHead sx={{ whiteSpace: 'nowrap' }}>
                        <TableRow
                            sx={{
                                ...(!darkMode && {
                                    '& > th': { backgroundColor: '#eeeef6' },
                                }),
                            }}
                        >
                            <TableCell
                                sx={{
                                    textAlign: 'left',
                                    width: '50px',
                                }}
                            >
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                    indeterminate={
                                        selectedCampaigns.length > 0 &&
                                        selectedCampaigns.length < (campaigns?.Results ?? []).length
                                    }
                                />
                            </TableCell>
                            <TableCell>
                                <Typography variant="lineDataLabel" onClick={() => handleSort('campaignName')}>
                                    TITLE
                                    {sortField === 'campaignName' && (sortDirection === 'asc' ? upArrow : downArrow)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="lineDataLabel" onClick={() => handleSort('user')}>
                                    OWNER
                                    {sortField === 'user' && (sortDirection === 'asc' ? upArrow : downArrow)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="lineDataLabel" onClick={() => handleSort('campaignStatus')}>
                                    STATUS
                                    {sortField === 'campaignStatus' && (sortDirection === 'asc' ? upArrow : downArrow)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="lineDataLabel" onClick={() => handleSort('members')}>
                                    MEMBERS
                                    {sortField === 'members' && (sortDirection === 'asc' ? upArrow : downArrow)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="lineDataLabel" onClick={() => handleSort('media')}>
                                    IRCODES
                                    {sortField === 'media' && (sortDirection === 'asc' ? upArrow : downArrow)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="lineDataLabel" onClick={() => handleSort('created')}>
                                    DATE
                                    {sortField === 'created' && (sortDirection === 'asc' ? upArrow : downArrow)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedCampaigns.map(campaign => (
                            <MyCampaignRow
                                key={campaign.campaignID}
                                campaign={campaign}
                                showCheckbox={true}
                                isSelected={selectedCampaigns.includes(campaign.campaignID.toString())}
                                onSelected={selected => {
                                    handleSelectCampaign(campaign.campaignID.toString());
                                }}
                                onMembersClick={selectedCampaign => {
                                    setSelectedManage(selectedCampaign);
                                    setShowManageMembers(true);
                                }}
                                onDeleted={() => fetchAndSet()}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ManageMembersDialog
                open={showManageMembers}
                onClose={() => {
                    const nextQueue = [...manageQueue];
                    const currentCampaignID = nextQueue.shift();
                    setManageQueue(nextQueue);
                    setSelectedCampaigns(prevSelected => prevSelected.filter(id => id !== currentCampaignID));
                    if (nextQueue.length === 0) {
                        setShowManageMembers(false);
                        setSelectedManage(undefined);
                        setSelectAll(false);
                        setRefresh(prev => !prev);
                    } else {
                        const nextCampaign = campaigns?.Results.find(
                            campaign => nextQueue[0] === campaign.campaignID.toString(),
                        );
                        setSelectedManage(nextCampaign || undefined);
                    }
                }}
                campaign={selectedManage!}
            />
            <Stack
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: darkMode ? '#323241' : Color.White,
                    borderTop: '1px solid',
                    borderColor: darkMode ? '#555' : '#E5E5E5',
                    py: 1,
                }}
            >
                <Pagination
                    count={Math.ceil((campaigns?.Results.length ?? 0) / itemsPerPage)}
                    page={currentPage}
                    limit={itemsPerPage}
                    total={campaigns?.Results.length}
                    onChange={handlePageChange}
                    onLimitChange={handleItemsPerPageChange}
                />
            </Stack>
        </Stack>
    );
}
