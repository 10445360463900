import { Box, Button, Container, List, ListItem, SwipeableDrawer, Typography } from '@mui/material';
import { useContext } from 'react';
import UserContext, { TUser } from '../../contexts/UserContext';
import { Color } from '../../Color';
import { ZIndex } from '../../App';
import EditProfile from './EditProfile';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import useBooleanState from '../../hooks/useBooleanState';
import AccountPrivacy from './AccountPrivacy';

interface Props {
    setAuthOpen: (open: boolean) => void;
    closeMenu: (closeProfile?: boolean) => void;
}

export default function ProfileSubMenu({ setAuthOpen, closeMenu }: Props) {
    const { darkMode, setDarkMode } = useContext(ThemeContext) as TTheme;
    const { user, userIsAnonymous, signOut } = useContext(UserContext) as TUser;

    const toggleTheme = () => {
        setDarkMode(!darkMode);
    };

    const [editProfileOpen, { setTrue: setEditProfileOpen, setFalse: setEditProfileClosed }] = useBooleanState(false);
    const [accountPrivacyOpen, { setTrue: setAccountPrivacyOpen, setFalse: setAccountPrivacyClosed }] =
        useBooleanState(false);

    const handleSignOut = async () => {
        try {
            await signOut();
            closeMenu(true);
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const navItems = [
        {
            label: 'EDIT PROFILE',
            icon: () => <i className="fa-light fa-pen" />,
            onClick: () => {
                setEditProfileOpen();
            },
        },
        {
            label: 'ACCOUNT PRIVACY',
            icon: () => <i className="fa-light fa-lock" />,
            onClick: () => {
                setAccountPrivacyOpen();
            },
        },
        {
            label: `APP THEME: ${darkMode ? 'DARK' : 'LIGHT'}`,
            icon: () => <i className={`fa-light fa-${darkMode ? 'moon' : 'sun'}`}></i>,
            onClick: toggleTheme,
        },
        {
            label: 'ABOUT IRCODE',
            icon: () => <i className="fa-sharp fa-light fa-globe"></i>,
            onClick: () => {
                window.open('https://ircode.com/about/');
            },
        },
        // { label: 'Cookie', onClick: () => { navigate('cookie') } },
    ];

    if (user) {
        if (userIsAnonymous) {
            navItems.push({
                label: 'Sign In',
                icon: () => <i className="fa-light fa-right-to-bracket"></i>,
                onClick: () => {
                    setAuthOpen(true);
                },
            });
        } else {
            navItems.push({
                label: 'Sign Out',
                icon: () => <i className="fa-light fa-arrow-right-from-bracket"></i>,
                onClick: handleSignOut,
            });
        }
    } else {
        navItems.push({
            label: 'Sign In',
            icon: () => <i className="fa-light fa-right-to-bracket"></i>,
            onClick: () => {
                setAuthOpen(true);
            },
        });
    }

    const authDrawerBleeding = 56;
    const renderNavItems = () => (
        <List
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                color: Color.Black,
                alignItems: 'center',
            }}
        >
            {navItems.map(item => (
                <ListItem
                    key={item.label}
                    disablePadding
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                >
                    <Button onClick={item.onClick} variant="irdbText">
                        <Typography
                            component="span"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '0.5rem',
                                fontFamily: 'Nunito Sans',
                                fontSize: '1.125rem',
                                fontWeight: 400,
                                lineHeight: '1.625rem',
                                letterSpacing: '0.15em',
                                color: Color.Black,
                            }}
                        >
                            {item.label}
                            {item.icon ? item.icon() : null}
                        </Typography>
                    </Button>
                </ListItem>
            ))}

            <ListItem disablePadding>
                <Button
                    variant="irdbText"
                    sx={{
                        color: Color.Black,
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                    onClick={() => {
                        closeMenu();
                    }}
                >
                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '0.5rem',
                            fontFamily: 'Nunito Sans',
                            fontSize: '1.125rem',
                            fontWeight: 400,
                            lineHeight: '1.625rem',
                            letterSpacing: '0.15em',
                            color: Color.Black,
                        }}
                    >
                        Close <i className="fa-sharp fa-light fa-xmark"></i>
                    </Typography>
                </Button>
            </ListItem>
        </List>
    );

    return (
        <Container
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                }}
            >
                {renderNavItems()}
            </Box>

            <SwipeableDrawer
                anchor="bottom"
                open={editProfileOpen}
                swipeAreaWidth={authDrawerBleeding}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                }}
                PaperProps={{
                    sx: {
                        height: '100%',
                    },
                }}
                onClose={setEditProfileClosed}
                onOpen={() => {}}
                style={{
                    zIndex: ZIndex.ProfileSubMenu,
                }}
            >
                <EditProfile onClose={setEditProfileClosed} />
            </SwipeableDrawer>

            <SwipeableDrawer
                anchor="bottom"
                open={accountPrivacyOpen}
                swipeAreaWidth={authDrawerBleeding}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                }}
                PaperProps={{
                    sx: {
                        height: '100%',
                    },
                }}
                onClose={setAccountPrivacyClosed}
                onOpen={() => {}}
                style={{
                    zIndex: ZIndex.ProfileSubMenu,
                }}
            >
                <AccountPrivacy onClose={setAccountPrivacyClosed} />
            </SwipeableDrawer>
        </Container>
    );
}
